<template>
  <nav class="navbar" role="navigation" min-height="256px" aria-label="navigation">
    <div class="navbar-brand" min-height="256px">
      <a class="navbar-item" href="https://www.anchesetsoufflet.fr">
        <img src="./assets/logo-horizontal-mano.svg" min-height="256px" height="256px">
      </a>
    </div>
  </nav>
  <section>
    <diatonic-keyboards/>
  </section>
  <footer>
    <p>Réalisé par <a href="https://www.linkedin.com/in/pierre-verkest/">Pierre Verkest</a> - Version {{version}}</p>
  </footer>
</template>

<script>
import DiatonicKeyboards from '@/components/Keyboards.vue'

export default {
  name: 'DiatonicChord',
  components: {
    DiatonicKeyboards
  },
  data: () => ({
    version: "1.4.1",
  })
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #034488;
}
footer {
  color: black;
  text-align: right;
  margin: 1em;
}
</style>
