<template>


  <article class="panel is-primary" v-if="displayKeyboardSettings">
    <p class="panel-heading">
      Options de clavier
      <button class="delete" @click="displayKeyboardSettings = false"></button>
    </p>
    <div class="panel-block panel-keyboard">
      <div class="columns is-desktop">
        <div class="field column field-keyboard">
          <label class="label" >Clavier</label>
          <div class="control">
            <div class="select is-primary">
              <select 
                v-model="currentKeyboard"
                >
                  <optgroup :key="aesModel.code" :label="aesModel.fr" :value="aesModel" v-for="aesModel in models">
                    <option :key="modelKeyboardCode(aesModel.code,keyboard.code)" :value="keyboard" v-for="keyboard in getKeyboardsFromCodes(aesModel.keyboards)">
                        {{ keyboard.fr }}
                    </option>
                  </optgroup>
              </select>
            </div>
          </div>
          <p class="help is-small">Choisi le type clavier utilisé</p>
        </div>
        <div class="field column field-voices">
          <label class="label" >Voix</label>
          <div class="control">
            <label class="checkbox" :key="voice.code" v-for="voice in voices">
              <input
                type="checkbox"
                :id="'voice-' + voice.code"
                name="voices"
                :value="voice.code"
                :checked="desiredVoices.indexOf(voice.code)>-1"
                v-model="desiredVoices">
              {{ voice.fr }}
            </label>
          </div>
          <p class="help">Affiche le schéma des voix sélectionnées</p>
        </div>
        <div class="field column field-ref-note" v-if="desiredVoices && desiredVoices.length > 0">
          <label class="label" >Note de référence</label>
          <div class="control">
            <div class="select">
              <select 
                v-model="referenceNote"
                >
                <option :key="note.code" :value="note" v-for="note in referencesNotes">
                    {{ note.fr }}
                </option>
              </select>
            </div>
          </div>
          <p class="help">Choix de la fréquence et note de référence du Clavier</p>
        </div>
        <div class="field column field-chording-upper-tremolo" v-if="desiredVoices.indexOf('upper-tremolo')>-1">
          <label class="label" >Accordage flûte 1</label>
          <div class="control">
            <div class="select">
              <select 
                v-model="upperTremoloChording"
                >
                <option :key="chord.code" :value="chord" v-for="chord in chording">
                    {{ chord.fr }}
                </option>
              </select>
            </div>
          </div>
          <p class="help">Choix de l'accordage de la flûte 1</p>
        </div>
        <div class="field column field-chording-lower-tremolo" v-if="desiredVoices.indexOf('lower-tremolo')>-1">
          <label class="label" >Accordage flûte 2</label>
          <div class="control">
            <div class="select">
              <select 
                v-model="lowerTremoloChording"
                >
                <option :key="chord.code" :value="chord" v-for="chord in chording">
                    {{ chord.fr }}
                </option>
              </select>
            </div>
          </div>
          <p class="help">Choix de l'accordage de la flûte 2</p>
        </div>
      </div>
    </div>
    <div class="panel-block panel-theming">
      <div class="field">
        <label class="label is-small" >Thème d'affichage</label>
        <div class="control is-small">
          <div class="select is-small">
            <select 
              v-model="theme"
              >
              <option :key="theme.code" :value="theme.code" v-for="theme in themes">
                  {{ theme.fr }}
              </option>
            </select>
          </div>
        </div>
        <p class="help  is-small">Choisi le thème pour faire varier les couleurs du rendu</p>
      </div>
    </div>
  </article>

  <article class="panel is-primary" v-if="displayScaleSettings">
    <div class="panel-heading">
      Repérer une gamme ou un accord
      <button class="bulma-delete-mixin" @click="displayScaleSettings = false"></button>
    </div>
    <div class="panel-block panel-scale">
      <div class="columns is-desktop">
        <div class="field column">
          <label class="label" >Gamme</label>
          <div class="control">
            <div class="select is-primary">
              <select 
                v-model="currentScale"
                @input="desiredNotes={notes:[], chord: '', scale: ''}"
                >
                <option :key="code" :value="scale" v-for="[code, scale] in scales">
                  Gamme {{ scale.fr }}
                </option>
              </select>
            </div>
          </div>
          <p class="help">Dans quel gamme tu travail</p>
        </div>
        <div class="field column"  v-if="currentScale">
          <label class="label">Accord (de la gamme de {{ currentScale.fr }})</label>
          <div class="control">
            <div class="radio is-primary">
              <ul>
                <li :key="key" v-for="[key, degree] in degrees">
                  <div class="radio">
                    <label :for="currentScale.fr + '-' + key">
                      <input type="radio" :id="currentScale.fr + '-' + key" name="desired_notes" :value="{notes: degree.chord(currentScale.scale), chord: `${key}: ${degree.fr}`, scale: currentScale.fr}" v-model="desiredNotes">
                      {{ key }}: {{ degree.fr }} => <b>{{ degree.chord(currentScale.scale).join(" - ") }}</b>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p class="help">Choisi un accord parmi les accord de la gamme pour repérer les boutons sur le clavier</p>
        </div>
        <div class="field column"  v-if="currentScale">
          <label class="label">Notes de la gamme diatonique de {{ currentScale.fr }}</label>
          <div class="control">
            <div class="radio is-primary">
              <label class="radio" :for="currentScale.fr">
                <input type="radio" :id="currentScale.fr" key="currentScale.fr" name="desired_notes" :value="{notes: currentScale.scale, chord: '', scale: currentScale.fr}" v-model="desiredNotes">
                Notes de la gamme de {{ currentScale.fr }}: <b>{{ currentScale.scale.join(" - ") }}</b>
              </label>
            </div>
          </div>
          <p class="help">Affiche les positions pour l'ensemble des notes de la gamme.</p>
        </div>
      </div>
    </div>
    <div class="panel-block panel-pull-push">
      <div class="field">
        <label class="label">Sens du soufflet</label>
        <div class="control">
          <div class="select is-primary">
            <p>
              <select 
                v-model="state"
                >
                <option :key="state.code" :value="state.code" v-for="state in states">
                    {{ state.fr }}
                </option>
              </select>
            </p>
          </div>
        </div>
        <p class="help">Affiche les notes en fonction du sens du soufflet de l'accordéon</p>
      </div>
    </div>
  </article>
  <div>
      <article>
        <diatonic-keyboard
          v-if="currentKeyboard"
          :theme="theme"
          :keyboard="currentKeyboard"
          :width="keyboardSize.width"
          :height="keyboardSize.height"
          :state="state"
          :pressed-notes="desiredNotes.notes"
          :bellows-width="300"
          :voices="desiredVoices"
          :subtitle="subtitle"
          :reference-note="referenceNote"
          :upper-tremolo-chording="upperTremoloChording"
          :lower-tremolo-chording="lowerTremoloChording"
          />
      </article>
  </div>
</template>

<script>
import { MODELS, KEYBOARDS, SCALES, DEGREES, VOICES, NOTES_EN_TO_FR, REFERENCES_NOTES, CHORDING } from "@/keyboards"
import DiatonicKeyboard from '@/components/Keyboard.vue'

const A4_FACTOR = 1.4143;

export default {
  name: 'DiatonicKeyboards',
  components: {
    DiatonicKeyboard
  },
  data: () => ({
      displayKeyboardSettings: true,
      displayScaleSettings: true,
      currentKeyboard: KEYBOARDS["G-C-alts-treble"],
      currentScale: null,
      desiredNotes: {notes:[], chord: "", scale: ""},
      desiredVoices: [],
      theme: "bulma",
      themes: [
        {"code": "bulma", "fr": "Bulma"},
        {"code": "mediterranean", "fr": "Méditerranéen"},
        {"code": "petrol", "fr": "Pétrole"},
        {"code": "black-and-white", "fr": "Noir et Blanc"},
      ],
      state: "pull-push",
      states: [
        {"code": "pull-push", "fr": "Tiré/Poussé"},
        {"code": "pull", "fr": "Tiré"},
        {"code": "push", "fr": "Poussé"},
      ],
      referenceNote: REFERENCES_NOTES[0],
      lowerTremoloChording: CHORDING[1],
      upperTremoloChording: CHORDING[1],
  }),
  computed: {
    keyboardSize(){
      const size = {width: 120, height: 120 * A4_FACTOR}
      if(window.innerHeight / window.innerWidth > A4_FACTOR){
        size.width = window.innerWidth;
        size.height = size.width * A4_FACTOR;
      } else {
        size.height = window.innerHeight;
        size.width = size.height / A4_FACTOR;
      }
      return size;
    },
    keyboards(){
      return KEYBOARDS;
    },
    models(){
      return MODELS;
    },
    scales(){
      return SCALES;
    },
    degrees(){
      return DEGREES;
    },
    voices(){
      return VOICES;
    },
    notes(){
      return NOTES_EN_TO_FR;
    },
    subtitle(){
      let subtitle = "";
      if(this.desiredNotes.notes.length > 0){
        if(this.desiredNotes.chord===""){
          subtitle = "Notes";
        } else {
          subtitle = `Accord de ${this.notes[this.desiredNotes.notes[0]]} (${this.desiredNotes.chord})`;
        }
        subtitle += ` de la gamme de ${this.desiredNotes.scale}: ${this.desiredNotes.notes.map(note => this.notes[note])}`;
      }
      return subtitle
    },
    referencesNotes(){
      return REFERENCES_NOTES;
    },
    chording(){
      return CHORDING;
    }
    
  },
  watch: {
    currentKeyboard(){
      this.setHashUrlFromData();
    },
    // currentScale(){
    //   this.setHashUrlFromData();
    // },
    // desiredNotes(){
    //   this.setHashUrlFromData();
    // },
    // state(){
    //   this.setHashUrlFromData();
    // },
    desiredVoices(){
      this.setHashUrlFromData();
    },
    theme(){
      this.setHashUrlFromData();
    },
    referenceNote(){
      this.setHashUrlFromData();
    },
    lowerTremoloChording(){
      this.setHashUrlFromData();
    },
    upperTremoloChording(){
      this.setHashUrlFromData();
    },
    displayKeyboardSettings(){
      this.setHashUrlFromData();
    },
    displayScaleSettings(){
      this.setHashUrlFromData();
    },
  },
  mounted() {
    this.setDataFromHashUrl()
    window.addEventListener("hashchange", this.setDataFromHashUrl);
  },
  methods: {
    setHashUrlFromData(){
      window.location.hash = this.serializeParams();
    },
    serializeParams(){
      // const params = {
      //   "currentScale": this.currentScale,
      //   "desiredNotes": this.desiredNotes,
      //   "state": this.state,
      // }
      var dummyObj = new URL('https://dummy.com');
      if(this.currentKeyboard){
        dummyObj.searchParams.set("kb", this.currentKeyboard.code)
      }
      if(this.desiredVoices && this.desiredVoices.length > 0){
        dummyObj.searchParams.set("voices", JSON.stringify(this.desiredVoices))
      }
      if(this.referenceNote && this.desiredVoices && this.desiredVoices.length > 0){
        dummyObj.searchParams.set("note", this.referenceNote.code)
      }
      if(this.upperTremoloChording && this.desiredVoices.indexOf('upper-tremolo')>-1){
        dummyObj.searchParams.set("up-tune", this.upperTremoloChording.code)
      }
      if(this.lowerTremoloChording && this.desiredVoices.indexOf('lower-tremolo')>-1){
        dummyObj.searchParams.set("low-tune", this.lowerTremoloChording.code)
      }
      dummyObj.searchParams.set("theme", this.theme)
      if(this.displayKeyboardSettings !== true){
        dummyObj.searchParams.set("opt-kb", this.displayKeyboardSettings)
      }
      if(this.displayScaleSettings !== true){
        dummyObj.searchParams.set("opt-scale", this.displayScaleSettings)
      }
      return dummyObj.searchParams
    },
    setDataFromHashUrl(){
      const params = this.parseHashUrl(window.location.hash.substr(1))
      const kbCode = params.get("kb");
      if (kbCode){
        this.currentKeyboard = this.getKeyboardFromCode(kbCode);
      }

      const desiredVoices = params.get("voices")
      if (desiredVoices){
        this.desiredVoices = JSON.parse(desiredVoices);
      }
      const note = this.referencesNotes.filter(note => note.code === params.get("note"))
      if (note.length > 0){
        this.referenceNote = note[0] 
      }
      let tune = this.chording.filter(chord => chord.code === params.get("up-tune"))
      if (tune.length > 0){
        this.upperTremoloChording = tune[0] 
      }
      tune = this.chording.filter(chord => chord.code === params.get("low-tune"))
      if (tune.length > 0){
        this.lowerTremoloChording = tune[0] 
      }
      const theme = this.themes.filter(theme => theme.code === params.get("theme"))
      if (theme.length > 0){
        this.theme = theme[0].code 
      }
      this.displayKeyboardSettings = params.get("opt-kb") === "true" || params.get("opt-kb") === null;
      this.displayScaleSettings = params.get("opt-scale") === "true" || params.get("opt-scale") === null;
    },
    parseHashUrl(hash){
      var dummyObj = new URL('https://dummy.com');
      dummyObj.search = hash
      return dummyObj.searchParams
    },
    getKeyboardFromCode(code){
      return this.keyboards[code];
    },
    getKeyboardsFromCodes(keyboards){
      return keyboards.map(code => this.getKeyboardFromCode(code));
    },
    modelKeyboardCode(aesModelCode, kbCode){
      return aesModelCode + "-" + kbCode;
    }
  }
}
</script>

<style>

article.panel {
  text-align: left;
}
.checkbox {
  margin-left: 30px;
}
</style>
