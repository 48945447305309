<template>
  <svg :x="x" :y="y" 
      :width="width"
      :height="height"
      viewport="0 0 600 100"
      >
    <g
      transform="translate(0, 300) rotate(-90)"
      transform-origin="40 0"
      id="blt-address">
      <text x="0" y="30"
        :fill="color"
       class="blt-address-name">Anches et Soufflet</text>
      <text x="0" y="60" class="blt-address">26 route de Châteaulin</text>
      <text x="0" y="90" class="blt-address">29 550 - PLOMODIERN</text>
      <text x="0" y="120" class="blt-address">-- FRANCE --</text>
      <text x="0" y="150" class="blt-address">+33 - (0)6.16.66.74.92</text>
      <text x="0" y="180" class="blt-address">contact@anchesetsoufflet.fr</text>
    </g>
  </svg>
</template>

<script>
  export default {
    name: 'AddressBlt',
    props: {
      x: {
        type: Number,
        default: 0
      },
      y: {
        type: Number,
        default: 0
      },
      width: {
        type: Number,
        default: 120
      },
      height: {
        type: Number,
        default: 360
      },
      color: {
        type: String,
        default: "black"
      },
    }
  }
</script>
<style>
  .blt-address-name{
    font: bold 30px sans-serif;
  }
  .blt-address{
    font: italic 23px sans-serif;
  }
</style>