<template>
  <svg :x="x" :y="y">
    <circle
      v-if="pressedNotes.length === 0"
      fill="#000000"
      fill-opacity="1"
      stroke="#000000"
      stroke-width="2"
      stroke-opacity="1"
      cx="55"
      cy="59"
      r="49" />
    <path
      v-if="state === 'pull-push'"
      id="push-background"
      :fill="compute_color('pushBgColor', button.push)"
      fill-opacity="1"
      strock="none"
      d="M 0,50 A 50 50, 0, 0, 1, 100,50 Z"/>
    <path
      v-if="state === 'pull-push'"
      id="pull-background"
      :fill="compute_color('pullBgColor', button.pull)"
      fill-opacity="1"
      strock="none"
      d="M 0,50 A 50 50, 0, 0, 0, 100,50 Z"/>
    <circle
      :fill="circleBgColor"
      :fill-opacity="state === 'pull-push' ? 0 : 1"
      stroke="#000000"
      stroke-width="2"
      stroke-opacity="1"
      cx="50"
      cy="50"
      r="49" />
    <path
      v-if="state === 'pull-push'"
      fill="#69997d"
      fill-opacity="1"
      stroke="#000000"
      stroke-width="2"
      stroke-opacity="0.6"
      d="M 0,50 L 100 50" />
    <g id="position" v-if="displayPosition">
      <path
        :fill="compute_color('positionBgColor', button.pull, button.push)"
        style="fill-opacity:1;stroke:none"
        d="M 0,50 A 50 50, 0, 0, 1, 50,0 L 0 0 Z" />
      <text
        :fill="compute_color('positionTextColor', button.pull, button.push)"
        style="font-size:14px;font-family:Verdana, Helvetica, Arial, sans-serif;font-weight:bold"
        x="1"
        y="14"
        text-anchor="left"
        id="button-number-text">{{ button.position }}{{ rowSymbol }}</text>
    </g>
    <g v-if="state === 'pull-push'">
      <text
        :fill="compute_color('pushTextColor', button.push)"
        style="font-size:30px;font-family:Verdana, Helvetica, Arial, sans-serif;font-weight:bold"
        x="50"
        y="40"
        text-anchor="middle"
        id="button-push-note">{{ push_note }}</text>
      <text
        :fill="compute_color('pullTextColor', button.pull)"
        style="font-size:30px;font-family:Verdana, Helvetica, Arial, sans-serif;font-weight:bold"
        x="50"
        y="80"
        text-anchor="middle"
        id="button-pull-note">{{ pull_note }}</text>
    </g>
    <g v-else-if="state === 'pull'">
      <text
        :fill="compute_color('pullTextColor', button.pull)"
        style="font-size:46px;font-family:Verdana, Helvetica, Arial, sans-serif;font-weight:bold"
        x="50"
        y="64"
        text-anchor="middle"
        id="button-pull-note">{{ pull_note }}</text>
    </g>
    <g v-else-if="state === 'push'">
      <text
        :fill="compute_color('pushTextColor', button.push)"
        style="font-size:46px;font-family:Verdana, Helvetica, Arial, sans-serif;font-weight:bold"
        x="50"
        y="64"
        text-anchor="middle"
        id="button-push-note">{{ push_note }}</text>
    </g>
  </svg>
</template>

<script>

import { NOTES } from "@/keyboards"

export default {
  name: 'DiatonicKeyboardButton',
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    button: {
      type: Object,
      default: () => {
        return {
          "position": 0,
          "push": "",
          "pull": ""
        }
      }
    },
    rowSymbol: {
      type: String,
      default: ""
    },
    displayPosition: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: "pull-push"
    },
    pressedNotes: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
  }),
  computed: {
    circleBgColor(){
      if(this.state === 'pull-push'){
        return "";
      }
      return this.compute_color(this.state + 'BgColor', this.state === 'pull' ? this.button.pull : this.button.push);
    },
    pull_note(){
      if(this.button.pull in NOTES){
        return NOTES[this.button.pull]["fr"];
      }else{
        return this.button.pull;
      }
    },
    push_note(){
      if(this.button.push in NOTES){
        return NOTES[this.button.push]["fr"];
      }else{
        return this.button.push;
      }
    },
  },
  methods: {
    compute_color(base, note1, note2){
      let pressed = false;
      const notes = [];
      if(note1){
        notes.push(note1)
      }
      if(note2){
        notes.push(note2)
      }
      notes.forEach(note => pressed = pressed || this.pressedNotes.includes(note));
      return `var(--${base}${pressed ? "Pressed" : ""})`
    }
  }
}
</script>

<style>
  /**
    mind to update export style as well
    and keep positionBgColor in sync in
    Voices.vue
  */
  .mediterranean {
    --pushBgColor: #034488;
    --pushTextColor: #fffcf6;
    --pushBgColorPressed: #fffcf6;
    --pushTextColorPressed: #50ad44;

    --pullBgColor: #ccdde8;
    --pullTextColor: #034488;
    --pullBgColorPressed: #fffcf6;
    --pullTextColorPressed: #50ad44;

    --positionBgColor: #edece8;
    --positionTextColor: #034488;
    --positionBgColorPressed: #034488;
    --positionTextColorPressed: #ccdde8;
  }

  .bulma {
    --pushBgColor: hsl(171, 100%, 41%);
    --pushTextColor: white;
    --pushBgColorPressed: hsl(207, 61%, 53%);
    --pushTextColorPressed: white;

    --pullBgColor: white;
    --pullTextColor: hsl(171, 100%, 41%);
    --pullBgColorPressed: hsl(0, 0%, 93%);
    --pullTextColorPressed: hsl(207, 61%, 53%);

    --positionBgColor: hsl(0, 0%, 96%);
    --positionTextColor: hsl(0, 0%, 48%);
    --positionBgColorPressed: hsl(44, 100%, 77%);
    --positionTextColorPressed:  hsl(229, 53%, 53%);
  }

  .petrol {
    --pushBgColor: #010C1C;
    --pushTextColor: #B0BED4;
    --pushBgColorPressed: #e66815;
    --pushTextColorPressed: #ffffff;

    --pullBgColor: #a2dddd;
    --pullTextColor: #001B16;
    --pullBgColorPressed: #2f660a;
    --pullTextColorPressed: #ffffff;

    --positionBgColor: #C8EBBF;
    --positionTextColor: #072400;
    --positionBgColorPressed: #000000;
    --positionTextColorPressed: #ffffff;
  }

  .black-and-white {
    --pushBgColor: #d2d2d2;
    --pushTextColor: white;
    --pushBgColorPressed: black;
    --pushTextColorPressed: white;

    --pullBgColor: white;
    --pullTextColor: #d2d2d2;
    --pullBgColorPressed: #e8e8e8;
    --pullTextColorPressed: black;

    --positionBgColor: #f1f1f1;
    --positionTextColor: #424242;
    --positionBgColorPressed: #c0c0c0;
    --positionTextColorPressed: black;
  }
</style>
