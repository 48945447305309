<template>
  <svg :x="x" :y="y" :width="width" :height="height" viewBox="0 0 2000 3000">
      <path
        :fill="color"
        d="M 1004.00,93.42
            C 994.64,94.84 986.69,96.41 978.00,100.49
            946.19,115.42 930.29,148.95 918.58,180.00
            913.39,193.74 907.64,209.71 904.42,224.00
            904.42,224.00 898.00,255.00 898.00,255.00
            894.19,273.38 889.23,292.09 889.00,311.00
            889.00,311.00 888.00,329.00 888.00,329.00
            888.00,329.00 888.00,341.00 888.00,341.00
            888.00,341.00 887.00,358.00 887.00,358.00
            886.98,369.37 886.90,381.59 879.47,390.99
            870.22,402.70 849.98,405.50 836.00,408.20
            836.00,408.20 774.00,415.43 774.00,415.43
            753.58,418.45 747.17,420.94 728.00,427.33
            728.00,427.33 667.00,448.45 667.00,448.45
            667.00,448.45 630.00,465.14 630.00,465.14
            630.00,465.14 589.00,484.31 589.00,484.31
            589.00,484.31 574.00,493.58 574.00,493.58
            574.00,493.58 531.00,523.42 531.00,523.42
            531.00,523.42 503.00,546.20 503.00,546.20
            481.20,563.57 459.17,580.75 439.00,600.04
            425.17,613.27 413.05,627.53 400.58,642.00
            400.58,642.00 382.88,663.00 382.88,663.00
            382.88,663.00 364.33,691.00 364.33,691.00
            364.33,691.00 345.42,719.00 345.42,719.00
            327.86,747.20 307.85,789.45 297.33,821.00
            290.06,842.83 282.27,869.92 282.00,893.00
            282.00,893.00 281.00,914.00 281.00,914.00
            280.94,950.08 280.00,986.38 313.00,1009.96
            321.32,1015.91 326.43,1018.11 336.00,1021.33
            344.40,1024.16 355.13,1026.89 364.00,1027.00
            393.44,1027.34 415.48,1016.25 433.79,993.00
            438.88,986.54 444.54,977.67 447.54,970.00
            459.18,940.23 439.70,905.73 406.00,907.04
            394.86,907.47 383.31,911.71 374.00,917.70
            370.23,920.12 360.64,928.10 357.00,928.44
            349.80,929.12 348.07,919.22 348.00,914.00
            347.80,897.28 351.76,883.72 357.00,868.00
            366.09,840.73 373.55,824.92 387.72,800.00
            387.72,800.00 410.58,762.00 410.58,762.00
            410.58,762.00 435.43,721.00 435.43,721.00
            444.62,708.14 454.57,695.72 465.17,684.00
            465.17,684.00 475.83,673.00 475.83,673.00
            475.83,673.00 504.01,642.00 504.01,642.00
            504.01,642.00 514.00,632.91 514.00,632.91
            514.00,632.91 536.00,612.87 536.00,612.87
            552.20,598.92 566.40,589.38 584.00,577.67
            593.16,571.57 602.00,564.59 612.00,559.89
            625.31,553.64 638.62,549.86 649.90,562.02
            660.52,573.46 657.38,589.60 653.00,603.00
            653.00,603.00 638.58,653.00 638.58,653.00
            638.58,653.00 631.14,676.00 631.14,676.00
            631.14,676.00 620.28,717.00 620.28,717.00
            620.28,717.00 602.42,778.00 602.42,778.00
            602.42,778.00 597.39,805.00 597.39,805.00
            597.39,805.00 591.27,838.00 591.27,838.00
            591.27,838.00 585.92,876.00 585.92,876.00
            585.92,876.00 580.28,907.00 580.28,907.00
            580.28,907.00 575.83,957.00 575.83,957.00
            575.83,957.00 572.00,996.00 572.00,996.00
            572.00,996.00 572.00,1094.00 572.00,1094.00
            572.00,1094.00 574.00,1124.00 574.00,1124.00
            574.00,1124.00 574.00,1154.00 574.00,1154.00
            574.00,1154.00 575.00,1170.00 575.00,1170.00
            575.00,1170.00 575.00,1187.00 575.00,1187.00
            574.99,1195.52 573.77,1207.40 567.79,1213.91
            557.54,1225.05 537.37,1221.34 524.00,1219.08
            518.60,1218.17 512.72,1216.66 508.00,1213.79
            491.55,1203.78 499.79,1188.66 502.39,1174.00
            503.21,1169.37 503.01,1164.69 503.00,1160.00
            502.96,1133.72 484.42,1117.27 459.00,1115.32
            456.17,1115.11 453.80,1115.64 451.00,1115.95
            423.24,1119.06 402.33,1141.85 402.00,1170.00
            402.00,1170.00 402.00,1174.00 402.00,1174.00
            402.07,1222.75 458.52,1256.80 501.00,1265.79
            501.00,1265.79 539.00,1271.59 539.00,1271.59
            546.30,1272.77 554.14,1274.20 559.70,1279.47
            568.44,1287.75 568.33,1299.92 569.09,1311.00
            569.09,1311.00 570.00,1323.00 570.00,1323.00
            570.00,1323.00 570.00,1359.00 570.00,1359.00
            570.00,1359.00 573.00,1400.00 573.00,1400.00
            573.00,1400.00 574.00,1419.00 574.00,1419.00
            574.00,1419.00 574.00,1512.00 574.00,1512.00
            574.00,1512.00 575.00,1527.00 575.00,1527.00
            575.00,1527.00 574.00,1545.00 574.00,1545.00
            574.00,1545.00 574.00,1561.00 574.00,1561.00
            574.00,1561.00 574.96,1572.00 574.96,1572.00
            574.96,1572.00 574.96,1584.00 574.96,1584.00
            574.96,1584.00 574.00,1601.00 574.00,1601.00
            574.00,1601.00 574.00,1623.00 574.00,1623.00
            574.00,1623.00 573.00,1636.00 573.00,1636.00
            573.00,1636.00 573.00,1649.00 573.00,1649.00
            573.00,1649.00 572.09,1660.00 572.09,1660.00
            572.09,1660.00 569.17,1692.00 569.17,1692.00
            569.17,1692.00 566.83,1725.00 566.83,1725.00
            566.83,1725.00 564.00,1754.00 564.00,1754.00
            563.71,1779.45 559.87,1804.71 557.16,1830.00
            555.90,1841.80 555.51,1856.94 546.82,1865.90
            538.95,1874.01 529.26,1873.05 519.00,1873.00
            498.79,1872.90 468.08,1865.73 450.00,1856.75
            441.98,1852.77 431.91,1847.04 428.85,1838.00
            425.50,1828.08 434.84,1814.71 435.00,1801.00
            435.07,1794.60 435.29,1790.22 433.25,1784.00
            425.24,1759.53 398.97,1747.12 375.00,1745.32
            370.89,1745.01 361.17,1746.56 357.00,1747.63
            334.42,1753.41 317.98,1768.73 317.04,1793.00
            316.23,1813.90 327.33,1831.83 340.42,1847.00
            355.00,1863.88 370.33,1871.30 390.00,1880.31
            414.34,1891.45 439.06,1902.01 465.00,1908.85
            465.00,1908.85 513.00,1918.40 513.00,1918.40
            526.37,1921.09 535.34,1924.54 534.99,1940.00
            534.84,1946.37 530.14,1964.94 528.37,1972.00
            521.61,1999.06 512.73,2023.03 500.25,2048.00
            500.25,2048.00 474.99,2097.00 474.99,2097.00
            464.18,2115.51 451.58,2132.77 438.88,2150.00
            438.88,2150.00 416.83,2179.00 416.83,2179.00
            410.19,2186.36 402.34,2192.53 395.00,2199.17
            395.00,2199.17 375.00,2217.72 375.00,2217.72
            375.00,2217.72 349.00,2238.37 349.00,2238.37
            349.00,2238.37 331.00,2251.98 331.00,2251.98
            312.12,2265.00 286.98,2277.12 265.00,2283.72
            254.42,2286.89 242.10,2290.87 231.00,2291.00
            220.19,2291.12 204.20,2289.64 202.21,2276.00
            202.21,2276.00 203.99,2255.00 203.99,2255.00
            203.50,2233.81 180.14,2216.24 160.00,2216.00
            160.00,2216.00 154.00,2216.00 154.00,2216.00
            125.02,2216.34 99.92,2243.15 101.04,2272.00
            102.17,2301.19 127.02,2320.74 153.00,2328.97
            163.69,2332.36 174.92,2333.84 186.00,2335.28
            186.00,2335.28 208.00,2337.00 208.00,2337.00
            237.74,2337.05 263.19,2335.90 292.00,2326.98
            304.53,2323.09 315.48,2316.86 327.00,2310.78
            354.08,2296.49 372.53,2284.74 396.00,2264.73
            426.02,2239.13 429.25,2234.65 455.17,2206.00
            455.17,2206.00 482.11,2176.00 482.11,2176.00
            509.64,2140.39 532.17,2101.44 551.74,2061.00
            551.74,2061.00 585.95,1977.00 585.95,1977.00
            591.38,1962.19 594.85,1944.11 609.00,1935.13
            621.22,1927.38 649.18,1928.24 664.00,1926.83
            664.00,1926.83 720.00,1919.27 720.00,1919.27
            720.00,1919.27 785.00,1911.16 785.00,1911.16
            798.83,1909.69 822.71,1902.79 828.20,1921.00
            829.14,1924.10 829.03,1927.77 829.00,1931.00
            829.00,1931.00 824.28,1967.00 824.28,1967.00
            824.28,1967.00 819.85,2005.00 819.85,2005.00
            819.85,2005.00 815.17,2041.00 815.17,2041.00
            815.17,2041.00 805.17,2131.00 805.17,2131.00
            805.17,2131.00 803.91,2154.00 803.91,2154.00
            803.91,2154.00 801.09,2183.00 801.09,2183.00
            801.09,2183.00 797.83,2227.00 797.83,2227.00
            797.83,2227.00 787.16,2311.00 787.16,2311.00
            787.16,2311.00 784.83,2337.00 784.83,2337.00
            784.83,2337.00 782.17,2359.00 782.17,2359.00
            782.17,2359.00 779.72,2386.00 779.72,2386.00
            779.72,2386.00 765.43,2477.00 765.43,2477.00
            765.43,2477.00 758.58,2531.00 758.58,2531.00
            758.58,2531.00 738.88,2617.00 738.88,2617.00
            738.88,2617.00 730.35,2658.00 730.35,2658.00
            726.06,2676.70 715.11,2712.68 707.58,2730.00
            691.00,2768.12 678.09,2782.85 652.08,2814.00
            633.67,2836.06 614.95,2856.73 589.00,2870.22
            576.32,2876.80 563.23,2880.22 550.00,2885.05
            550.00,2885.05 503.00,2905.75 503.00,2905.75
            483.42,2915.57 467.54,2929.67 467.01,2953.00
            466.87,2959.45 467.93,2965.96 470.23,2972.00
            474.18,2982.37 481.66,2989.70 491.00,2995.39
            521.08,3013.71 555.17,3005.69 584.00,2989.15
            597.80,2981.23 616.98,2966.44 627.83,2954.96
            627.83,2954.96 658.16,2920.00 658.16,2920.00
            677.63,2898.20 705.59,2864.78 721.98,2841.00
            721.98,2841.00 740.60,2811.00 740.60,2811.00
            748.98,2797.04 754.84,2787.23 761.14,2772.00
            761.14,2772.00 772.42,2741.00 772.42,2741.00
            786.48,2704.04 795.80,2681.31 805.12,2642.00
            805.12,2642.00 814.43,2586.00 814.43,2586.00
            822.01,2540.88 829.30,2495.49 834.16,2450.00
            834.16,2450.00 843.83,2362.00 843.83,2362.00
            843.83,2362.00 846.09,2327.00 846.09,2327.00
            846.09,2327.00 848.09,2299.00 848.09,2299.00
            848.09,2299.00 849.00,2289.00 849.00,2289.00
            849.30,2262.85 854.27,2236.88 857.87,2211.00
            857.87,2211.00 863.72,2169.00 863.72,2169.00
            863.72,2169.00 869.25,2128.00 869.25,2128.00
            869.25,2128.00 877.20,2091.00 877.20,2091.00
            877.20,2091.00 892.25,2004.00 892.25,2004.00
            892.25,2004.00 897.59,1976.00 897.59,1976.00
            901.13,1954.85 905.27,1923.03 914.81,1904.00
            926.06,1881.58 956.74,1876.61 979.00,1872.00
            979.00,1872.00 1011.00,1862.72 1011.00,1862.72
            1011.00,1862.72 1042.00,1853.31 1042.00,1853.31
            1042.00,1853.31 1071.00,1842.05 1071.00,1842.05
            1071.00,1842.05 1096.00,1833.19 1096.00,1833.19
            1128.62,1820.55 1175.36,1795.01 1206.00,1777.01
            1231.54,1762.01 1256.88,1746.82 1281.00,1729.57
            1281.00,1729.57 1318.00,1699.80 1318.00,1699.80
            1318.00,1699.80 1354.00,1669.91 1354.00,1669.91
            1354.00,1669.91 1400.73,1619.00 1400.73,1619.00
            1419.65,1596.81 1450.60,1554.64 1463.74,1529.00
            1479.01,1499.20 1487.21,1470.69 1496.72,1439.00
            1509.03,1397.97 1512.61,1380.45 1517.16,1338.00
            1517.16,1338.00 1519.83,1317.00 1519.83,1317.00
            1519.83,1317.00 1521.09,1297.00 1521.09,1297.00
            1521.09,1297.00 1523.00,1279.00 1523.00,1279.00
            1523.00,1279.00 1523.00,1271.00 1523.00,1271.00
            1523.00,1271.00 1523.98,1262.00 1523.98,1262.00
            1523.98,1262.00 1523.00,1246.00 1523.00,1246.00
            1523.00,1246.00 1523.00,1237.00 1523.00,1237.00
            1523.00,1237.00 1522.00,1222.00 1522.00,1222.00
            1521.97,1203.08 1517.72,1187.28 1514.42,1169.00
            1510.21,1145.66 1505.93,1121.86 1499.58,1099.00
            1490.69,1067.02 1466.44,1024.36 1449.72,995.00
            1442.47,982.28 1425.94,960.88 1425.09,947.00
            1424.35,934.91 1434.06,921.25 1439.70,911.00
            1439.70,911.00 1454.74,884.00 1454.74,884.00
            1454.74,884.00 1473.74,844.00 1473.74,844.00
            1473.74,844.00 1495.00,787.00 1495.00,787.00
            1501.16,768.52 1510.87,736.65 1514.39,718.00
            1514.39,718.00 1519.17,677.00 1519.17,677.00
            1519.17,677.00 1521.00,654.00 1521.00,654.00
            1521.00,654.00 1522.00,633.00 1522.00,633.00
            1522.00,633.00 1522.00,605.00 1522.00,605.00
            1521.99,596.67 1519.48,575.92 1517.25,568.00
            1516.19,564.22 1514.13,557.91 1509.96,556.66
            1502.79,554.51 1499.30,567.16 1497.67,572.00
            1497.67,572.00 1477.33,631.00 1477.33,631.00
            1477.33,631.00 1463.81,664.00 1463.81,664.00
            1463.81,664.00 1448.14,708.00 1448.14,708.00
            1434.66,738.27 1417.21,766.65 1400.20,795.00
            1400.20,795.00 1371.14,840.00 1371.14,840.00
            1366.44,846.58 1360.14,855.59 1354.00,860.67
            1350.05,863.94 1345.20,866.45 1340.00,866.79
            1332.43,867.28 1313.71,859.74 1306.00,856.81
            1274.27,844.73 1262.95,844.13 1230.00,840.83
            1230.00,840.83 1207.00,839.00 1207.00,839.00
            1207.00,839.00 1186.00,838.00 1186.00,838.00
            1186.00,838.00 1161.00,838.00 1161.00,838.00
            1150.22,837.98 1131.95,837.22 1130.90,823.00
            1130.29,814.76 1135.10,803.80 1137.95,796.00
            1137.95,796.00 1148.42,762.00 1148.42,762.00
            1155.58,736.26 1164.31,710.00 1164.00,683.00
            1164.00,683.00 1163.08,673.00 1163.08,673.00
            1161.23,649.85 1156.65,630.94 1149.33,609.00
            1142.86,589.57 1131.79,560.31 1121.55,543.00
            1104.80,514.69 1083.63,492.64 1060.00,470.04
            1045.82,456.47 1022.44,438.26 1005.00,429.26
            987.56,420.26 978.87,416.24 962.00,405.00
            953.82,399.55 947.16,395.71 941.93,387.00
            937.36,379.39 936.61,369.69 935.92,361.00
            935.92,361.00 934.00,333.00 934.00,333.00
            934.00,333.00 933.04,316.00 933.04,316.00
            933.04,316.00 934.84,298.00 934.84,298.00
            937.89,269.45 942.74,246.01 955.75,220.00
            963.73,204.06 973.89,196.04 990.00,189.15
            995.74,186.69 1001.64,184.70 1008.00,185.09
            1017.08,185.65 1027.26,191.63 1036.00,194.48
            1047.38,198.19 1059.11,197.85 1070.00,192.68
            1095.89,180.37 1103.21,139.99 1083.91,119.00
            1078.48,113.09 1072.09,108.10 1065.00,104.32
            1049.50,96.06 1021.45,91.29 1004.00,93.42 Z
        M 861.00,503.29
        C 882.41,501.76 883.82,519.97 884.00,536.00
            884.00,536.00 885.00,551.00 885.00,551.00
            885.00,551.00 885.00,583.00 885.00,583.00
            885.00,583.00 888.00,621.00 888.00,621.00
            888.00,621.00 887.09,638.00 887.09,638.00
            885.06,667.69 880.99,696.58 876.42,726.00
            876.42,726.00 865.63,790.00 865.63,790.00
            865.63,790.00 851.33,836.00 851.33,836.00
            851.33,836.00 838.00,877.00 838.00,877.00
            838.00,877.00 830.34,898.00 830.34,898.00
            830.34,898.00 806.58,969.00 806.58,969.00
            806.58,969.00 778.31,1033.00 778.31,1033.00
            778.31,1033.00 763.22,1066.00 763.22,1066.00
            748.40,1097.36 734.83,1124.02 713.87,1152.00
            706.64,1161.66 698.14,1173.70 689.00,1181.41
            681.01,1188.15 669.16,1196.09 659.00,1198.84
            653.28,1200.39 647.04,1200.74 642.34,1196.44
            636.89,1191.46 636.09,1178.14 636.00,1171.00
            636.00,1171.00 636.00,1132.00 636.00,1132.00
            636.00,1132.00 637.00,1111.00 637.00,1111.00
            637.00,1111.00 637.91,1101.00 637.91,1101.00
            637.91,1101.00 639.91,1074.00 639.91,1074.00
            639.91,1074.00 643.17,1026.00 643.17,1026.00
            643.17,1026.00 646.83,995.00 646.83,995.00
            646.83,995.00 651.16,948.00 651.16,948.00
            651.16,948.00 663.42,869.00 663.42,869.00
            670.81,828.04 682.18,787.97 693.58,748.00
            693.58,748.00 702.42,715.00 702.42,715.00
            702.42,715.00 711.66,687.00 711.66,687.00
            711.66,687.00 722.31,654.00 722.31,654.00
            722.31,654.00 739.26,610.00 739.26,610.00
            739.26,610.00 761.80,572.00 761.80,572.00
            772.06,555.01 778.74,541.48 795.00,528.67
            813.03,514.46 838.51,506.69 861.00,503.29 Z
        M 984.00,535.43
        C 995.37,533.70 1003.57,539.71 1012.00,546.62
            1029.55,561.01 1044.32,582.24 1053.28,603.00
            1056.56,610.59 1059.29,616.99 1061.57,625.00
            1069.27,651.99 1070.04,687.89 1070.00,716.00
            1070.00,716.00 1066.27,749.00 1066.27,749.00
            1066.27,749.00 1060.58,786.00 1060.58,786.00
            1055.20,805.57 1039.83,840.34 1031.42,860.00
            1026.91,870.55 1023.97,877.44 1017.28,887.00
            1017.28,887.00 997.66,911.00 997.66,911.00
            993.19,916.71 982.96,930.61 983.41,938.00
            983.84,945.15 991.29,946.50 997.00,946.20
            997.00,946.20 1018.00,943.08 1018.00,943.08
            1018.00,943.08 1043.00,939.83 1043.00,939.83
            1043.00,939.83 1079.00,936.72 1079.00,936.72
            1104.25,933.65 1112.01,929.68 1140.00,930.00
            1140.00,930.00 1165.00,931.91 1165.00,931.91
            1182.84,933.13 1195.05,933.29 1213.00,937.00
            1224.45,939.37 1242.06,944.06 1252.00,949.88
            1259.04,953.99 1263.75,960.57 1261.37,969.00
            1258.36,979.63 1244.56,991.75 1237.28,1000.00
            1219.35,1020.34 1183.31,1054.55 1162.00,1071.60
            1144.65,1085.48 1137.02,1089.20 1119.00,1100.42
            1119.00,1100.42 1092.00,1117.94 1092.00,1117.94
            1092.00,1117.94 1066.00,1132.85 1066.00,1132.85
            1046.01,1144.32 1027.03,1157.04 1006.00,1166.69
            1006.00,1166.69 947.00,1191.01 947.00,1191.01
            947.00,1191.01 909.00,1207.58 909.00,1207.58
            909.00,1207.58 858.00,1229.31 858.00,1229.31
            858.00,1229.31 813.00,1253.14 813.00,1253.14
            813.00,1253.14 778.00,1272.30 778.00,1272.30
            762.66,1280.75 742.25,1295.94 728.00,1306.63
            715.39,1316.08 702.32,1324.97 691.00,1336.01
            682.01,1344.79 674.30,1354.39 666.25,1364.00
            662.66,1368.28 656.12,1375.95 651.00,1377.99
            644.52,1380.58 640.74,1376.96 638.78,1371.00
            636.82,1365.02 635.01,1345.65 635.00,1339.00
            635.00,1339.00 635.00,1301.00 635.00,1301.00
            635.14,1289.05 638.86,1273.93 649.00,1266.63
            655.79,1261.75 664.15,1260.81 672.00,1258.57
            682.47,1255.59 689.87,1252.25 699.00,1246.31
            699.00,1246.31 723.00,1227.80 723.00,1227.80
            723.00,1227.80 749.00,1205.91 749.00,1205.91
            764.54,1191.43 787.01,1163.45 800.11,1146.00
            800.11,1146.00 835.94,1095.00 835.94,1095.00
            835.94,1095.00 854.20,1063.00 854.20,1063.00
            854.20,1063.00 886.74,1007.00 886.74,1007.00
            886.74,1007.00 914.01,945.00 914.01,945.00
            914.01,945.00 928.58,910.00 928.58,910.00
            928.58,910.00 947.85,849.00 947.85,849.00
            947.85,849.00 955.39,817.00 955.39,817.00
            955.39,817.00 960.61,786.00 960.61,786.00
            965.33,760.80 973.22,735.58 975.09,710.00
            975.09,710.00 976.00,700.00 976.00,700.00
            976.00,700.00 977.00,681.00 977.00,681.00
            977.00,681.00 977.00,611.00 977.00,611.00
            977.00,611.00 976.04,599.00 976.04,599.00
            976.04,599.00 976.04,584.00 976.04,584.00
            976.04,584.00 975.00,570.00 975.00,570.00
            975.00,570.00 975.00,554.00 975.00,554.00
            975.09,546.47 976.29,538.54 984.00,535.43 Z
        M 1340.00,1037.34
        C 1360.00,1035.20 1369.04,1060.86 1374.34,1076.00
            1374.34,1076.00 1389.35,1123.00 1389.35,1123.00
            1389.35,1123.00 1395.61,1161.00 1395.61,1161.00
            1398.42,1176.58 1402.81,1198.49 1403.00,1214.00
            1403.00,1214.00 1404.00,1242.00 1404.00,1242.00
            1403.98,1257.45 1397.76,1290.08 1395.27,1307.00
            1395.27,1307.00 1384.12,1371.00 1384.12,1371.00
            1384.12,1371.00 1374.02,1405.00 1374.02,1405.00
            1374.02,1405.00 1357.45,1453.00 1357.45,1453.00
            1357.45,1453.00 1349.55,1474.00 1349.55,1474.00
            1349.55,1474.00 1331.22,1513.00 1331.22,1513.00
            1331.22,1513.00 1311.42,1544.00 1311.42,1544.00
            1311.42,1544.00 1277.77,1595.00 1277.77,1595.00
            1259.29,1619.10 1224.06,1656.15 1201.00,1675.56
            1201.00,1675.56 1178.00,1692.42 1178.00,1692.42
            1178.00,1692.42 1158.00,1706.98 1158.00,1706.98
            1135.24,1722.69 1111.01,1736.40 1086.00,1748.22
            1086.00,1748.22 1059.00,1760.31 1059.00,1760.31
            1059.00,1760.31 1007.00,1785.20 1007.00,1785.20
            1007.00,1785.20 998.00,1788.65 998.00,1788.65
            998.00,1788.65 990.00,1791.02 990.00,1791.02
            980.14,1794.09 959.57,1802.13 955.56,1787.00
            955.01,1784.96 954.96,1782.12 955.04,1780.00
            955.29,1773.71 959.42,1758.57 961.29,1752.00
            961.29,1752.00 972.28,1714.00 972.28,1714.00
            972.28,1714.00 988.98,1663.00 988.98,1663.00
            988.98,1663.00 1012.98,1584.00 1012.98,1584.00
            1012.98,1584.00 1028.34,1529.00 1028.34,1529.00
            1028.34,1529.00 1053.66,1455.00 1053.66,1455.00
            1053.66,1455.00 1071.69,1402.00 1071.69,1402.00
            1071.69,1402.00 1090.31,1352.00 1090.31,1352.00
            1090.31,1352.00 1100.08,1320.00 1100.08,1320.00
            1100.08,1320.00 1122.31,1261.00 1122.31,1261.00
            1122.31,1261.00 1138.69,1230.00 1138.69,1230.00
            1145.43,1217.54 1152.02,1205.31 1160.65,1194.00
            1175.85,1174.09 1196.63,1154.99 1217.00,1140.43
            1217.00,1140.43 1240.00,1124.58 1240.00,1124.58
            1264.02,1107.23 1286.08,1087.92 1307.00,1067.00
            1315.19,1058.81 1329.69,1040.12 1340.00,1037.34 Z
        M 968.00,1269.28
        C 972.41,1268.88 974.63,1268.93 979.00,1269.81
            1000.88,1274.18 1002.21,1292.43 1002.00,1311.00
            1001.76,1331.61 992.81,1364.12 986.33,1384.00
            986.33,1384.00 973.02,1423.00 973.02,1423.00
            973.02,1423.00 945.98,1514.00 945.98,1514.00
            945.98,1514.00 931.34,1557.00 931.34,1557.00
            931.34,1557.00 914.28,1614.00 914.28,1614.00
            914.28,1614.00 906.00,1638.00 906.00,1638.00
            899.14,1658.16 892.42,1678.22 887.65,1699.00
            887.65,1699.00 870.15,1767.00 870.15,1767.00
            870.15,1767.00 862.42,1797.00 862.42,1797.00
            858.74,1810.26 856.12,1828.32 844.96,1837.32
            833.28,1846.74 809.87,1848.14 795.00,1851.42
            795.00,1851.42 775.00,1856.35 775.00,1856.35
            775.00,1856.35 711.00,1868.73 711.00,1868.73
            711.00,1868.73 671.00,1872.17 671.00,1872.17
            671.00,1872.17 658.00,1873.17 658.00,1873.17
            644.68,1874.44 625.69,1876.86 620.11,1861.00
            618.10,1855.31 617.93,1847.99 618.00,1842.00
            618.00,1842.00 618.96,1831.00 618.96,1831.00
            618.96,1831.00 627.83,1741.00 627.83,1741.00
            627.83,1741.00 630.04,1714.00 630.04,1714.00
            630.04,1714.00 630.04,1705.00 630.04,1705.00
            630.04,1705.00 634.00,1650.00 634.00,1650.00
            634.00,1650.00 634.96,1638.00 634.96,1638.00
            634.96,1638.00 634.96,1624.00 634.96,1624.00
            634.96,1624.00 636.00,1609.00 636.00,1609.00
            636.00,1609.00 636.00,1581.00 636.00,1581.00
            636.00,1581.00 635.00,1567.00 635.00,1567.00
            634.91,1558.92 633.38,1537.43 644.00,1535.36
            650.92,1534.01 664.19,1547.58 670.00,1551.85
            683.63,1561.84 700.94,1568.80 718.00,1569.00
            736.60,1569.21 759.14,1567.71 775.00,1557.11
            792.00,1545.75 801.03,1525.18 800.26,1505.00
            799.68,1489.93 792.83,1475.18 782.83,1464.00
            769.67,1449.28 759.92,1447.01 743.00,1439.99
            735.30,1436.79 727.57,1433.44 727.04,1424.00
            726.48,1413.87 731.85,1406.76 737.63,1399.00
            748.79,1384.00 762.28,1370.91 777.00,1359.42
            777.00,1359.42 811.00,1333.43 811.00,1333.43
            826.80,1322.14 860.40,1302.39 878.00,1294.31
            895.85,1286.11 904.81,1284.90 923.00,1280.12
            940.48,1275.52 949.30,1272.03 968.00,1269.28 Z" />
    </svg>
</template>

<script>
  export default {
    name: 'LogoBlt',
    props: {
      x: {
        type: Number,
        default: 0
      },
      y: {
        type: Number,
        default: 0
      },
      width: {
        type: Number,
        default: 120
      },
      height: {
        type: Number,
        default: 360
      },
      color: {
        type: String,
        default: "black"
      },
    }
  }
</script>