<template>
  <button class="button is-primary" @click="download">Télécharger</button>
  <svg 
    id="diato"
    ref="diato"
    style="background-color:white"
    :width="width"
    :height="height"
    :viewBox="'0 0 ' + viewBoxWidth + ' ' + viewBoxHeight"
    :class="theme">
    <text
      style="font-size:25px;font-family:Verdana, Helvetica, Arial, sans-serif;font-weight:bold"
      x="0"
      y="30">{{ keyboard.fr }}</text>
    <text v-if="subtitle"
      style="font-size:25px;font-family:Verdana, Helvetica, Arial, sans-serif"
      x="0"
      y="70">{{ subtitle }}</text>
    <diatonic-keyboard-button
      :x="pullPushButtonX"
      :y="100"
      :button="{'pull': 'Tiré', 'push': 'Pou'}"
      :state="state"/>
    <diatonic-voices
      v-if="voices.length > 0"
      :voices="voices"
      :reference-note="referenceNote"
      :upper-tremolo-chording="upperTremoloChording"
      :lower-tremolo-chording="lowerTremoloChording"
      :x="voicesX"
      :y="100"/>
    <bellows :x="bellowsX" :y="bellowsY" :width="bellowsWidth" :height="bellowsHeight" :folder="6" :fillColor="bellowsColor"/>
    <logo-blt :x="bellowsX + 10" :width="bellowsWidth - 20" :y="bellowsY + 100" :height="360" :color="logoColor"/>
    <address-blt :x="bellowsX + 10" :y="bellowsY + bellowsHeight - 450" :width="bellowsWidth - 20" :height="400" :color="logoColor"/>
    <g id="left-hand">
      <g :key="row.code" :id="row.code" v-for="row in keyboard.left_rows">
        <diatonic-keyboard-button
          :state="state"
          :x="button_left_x(row)"
          :y="button_left_y(row, button)"
          :button="button"
          :key="row.code + '-' + button.position"
          v-for="button in row.buttons"/>
      </g>
    </g>
    <g id="right-hand">
      <g :key="row.code" :id="row.code" v-for="row in keyboard.right_rows">
        <diatonic-keyboard-button 
          :state="state"
          :x="button_x(row)"
          :y="button_y(row, button)"
          display-position
          :row-symbol="row.symbol"
          :button="button"
          :pressed-notes="right_hand_pressed_notes(button)"
          :key="row.code + '-' + button.position"
          v-for="button in row.buttons"/>
      </g>
    </g>
  </svg>
</template>

<script>

import { NOTES } from "@/keyboards"
import DiatonicKeyboardButton from '@/components/Button.vue'
import DiatonicVoices from '@/components/Voices.vue'
import Bellows from '@/components/Bellows.vue'
import LogoBlt from '@/components/LogoBlt.vue'
import AddressBlt from '@/components/AddressBlt.vue'

export default {
  name: 'DiatonicKeyboard',
  components: {
    DiatonicKeyboardButton,
    DiatonicVoices,
    Bellows,
    LogoBlt,
    AddressBlt
  },
  props: {
    state: {
      type: String,
      default: "pull-push"
    },
    theme: {
      type: String,
      default: "mediterranean"
    },
    subtitle: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 1200,
    },
    headerHeight: {
      type: Number,
      default: 250,
    },
    buttonSize: {
      type: Number,
      default: 120,
    },
    bellowsWidth: {
      type: Number,
      default: 400,
    },
    keyboard: {
      type: Object,
      required: true,
      default: () => { return {
        "code": "",
        "fr": "",
        "symbol": "",
        "position": 0,
        "right_rows": [],
        "left_rows": []
      } }
    },
    referenceNote: {
      type: Object,
      default: null,
    },
    pressedNotes: {
      type: Array,
      default: () => []
    },
    voices: {
      type: Array,
      default: () => []
    },
    upperTremoloChording: {
      type: Object,
      default: null,
    },
    lowerTremoloChording: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    currentKeyboard: null,
  }),
  computed: {
    viewBoxWidth(){
      return this.keyboard.left_rows.length * this.buttonSize + this.bellowsWidth + this.buttonSize * this.keyboard.right_rows.length;
    },
    viewBoxHeight(){
      let height = 500;
      if(this.keyboard.right_rows.length > 0 && this.keyboard.right_rows[0].buttons.length > 0){
        height = this.keyboard.right_rows[0].buttons.length * this.buttonSize + this.headerHeight;
      }
      return height;
    },
    pullPushButtonX(){
      return this.keyboard.left_rows.length * (this.buttonSize / 2) - (this.buttonSize / 2)
    },
    voicesX(){
      return this.keyboard.left_rows.length * this.buttonSize
    },
    bellowsX(){
      let x = this.keyboard.left_rows.length * this.buttonSize;
      return x
    },
    bellowsY(){
      let y = this.headerHeight;
      return y
    },
    bellowsHeight(){
      let height = this.viewBoxHeight - this.headerHeight ;
      return height
    },
    bellowsColor(){
      return {"bulma": "hsl(171, 100%, 41%)", "mediterranean": "#ccdde8", "petrol": "#C8EBBF","black-and-white": "#f1f1f1"}[this.theme]
    },
    logoColor(){
      return {"bulma": "hsl(207, 61%, 53%)", "mediterranean": "#034488", "petrol": "#010C1C","black-and-white": "#black"}[this.theme]
    }
  },
  methods: {
    right_hand_pressed_notes(button){
      const pressed = [];
      let pull = button.pull;
      let push = button.push;
      if(pull in NOTES){
        pull = NOTES[pull]["note"];
      }
      if (['pull-push', "pull"].includes(this.state) && this.pressedNotes.includes(pull)){
        pressed.push(button.pull)
      }
      if(push in NOTES){
        push = NOTES[push]["note"];
      }
      if (['pull-push', "push"].includes(this.state) && this.pressedNotes.includes(push)){
        pressed.push(button.push)
      }
      return pressed;
    },
    button_x(row){
      return this.viewBoxWidth - this.buttonSize * row.position;
    },
    button_y(row, button){
      const offset = (row.position - 1) * - this.buttonSize / 2; 
      return this.viewBoxHeight - this.buttonSize * button.position + offset ;
    },
    button_left_x(row){
      return (row.position - 1) * this.buttonSize;
    },
    button_left_y(row, button){
      const offset = (this.viewBoxHeight - row.buttons.length * this.buttonSize - this.headerHeight) / 2 ;
      return this.viewBoxHeight - this.buttonSize * button.position - offset;
    },
    download(){
      let styleElement = document.createElementNS("http://www.w3.org/2000/svg", "style");
      styleElement.textContent = this.get_svg_css();
      this.$refs.diato.appendChild(styleElement);
      let svgString = new XMLSerializer().serializeToString(this.$refs.diato);
      // A4
      // inches 8.268 x 11.693
      // cm 21 x 29.7
      const A4_format = {width: 21, height: 29.7}
      const pixelPerCm = 40;
      // we assume current svg respect A4 proportion
      // if ((this.height/this.width).toFixed(4) !== (1.4143).toFixed(4)){
      //   alert(`Curieux le format du svg ne respecte pas le ratio d'un format A4 ! (reçu: ${(this.height/this.width).toFixed(4)}, attendu: 1.4143)`)
      // }

      let canvas = document.createElement("canvas");
      canvas.width=A4_format.width * pixelPerCm;
      canvas.height=A4_format.height * pixelPerCm;
      let qualityFActor = canvas.height / this.height;

      let ctx = canvas.getContext("2d");
      let DOMURL = self.URL || self.webkitURL || self;
      let img = new Image();
      let svg = new Blob([svgString], {type: "image/svg+xml;charset=utf-8"});
      let url = DOMURL.createObjectURL(svg);
      let a = document.createElement("a"); //Create <a>
      img.onload = function() {
          ctx.drawImage(img, 0, 0, this.width, this.height,0 , 0, this.width * qualityFActor, this.height * qualityFActor);
          let png = canvas.toDataURL("image/png", 1.0);
          a.href = png; //Image Base64 Goes here
          DOMURL.revokeObjectURL(png);
          a.click(); //Downloaded file
      };
      img.src = url;
      a.download = `${this.keyboard.code}.png`; //File name Here
    },
    get_svg_css(){
      // TODO: find a better way to avoid duplication
      // by loading style somehow https://stackoverflow.com/questions/51932825/how-to-access-style-definitions-with-javascript-in-vuejs-sfcs#59486516
      // an idea would be to refer to the same css file
      return `
      .blt-address-name{
        font: bold 30px sans-serif;
      }
      .blt-address{
        font: italic 23px sans-serif;
      }
      .bulma {
        --pushBgColor: hsl(171, 100%, 41%);
        --pushTextColor: white;
        --pushBgColorPressed: hsl(207, 61%, 53%);
        --pushTextColorPressed: white;

        --pullBgColor: white;
        --pullTextColor: hsl(171, 100%, 41%);
        --pullBgColorPressed: hsl(0, 0%, 93%);
        --pullTextColorPressed: hsl(207, 61%, 53%);

        --positionBgColor: hsl(0, 0%, 96%);
        --positionTextColor: hsl(0, 0%, 48%);
        --positionBgColorPressed: hsl(44, 100%, 77%);
        --positionTextColorPressed:  hsl(229, 53%, 53%);
      }
      .mediterranean {
        --pushBgColor: #034488;
        --pushTextColor: #fffcf6;
        --pushBgColorPressed: #fffcf6;
        --pushTextColorPressed: #50ad44;

        --pullBgColor: #ccdde8;
        --pullTextColor: #034488;
        --pullBgColorPressed: #fffcf6;
        --pullTextColorPressed: #50ad44;

        --positionBgColor: #edece8;
        --positionTextColor: #034488;
        --positionBgColorPressed: #034488;
        --positionTextColorPressed: #ccdde8;
      }

      .petrol {
        --pushBgColor: #010C1C;
        --pushTextColor: #B0BED4;
        --pushBgColorPressed: #e66815;
        --pushTextColorPressed: #ffffff;

        --pullBgColor: #a2dddd;
        --pullTextColor: #001B16;
        --pullBgColorPressed: #2f660a;
        --pullTextColorPressed: #ffffff;

        --positionBgColor: #C8EBBF;
        --positionTextColor: #072400;
        --positionBgColorPressed: #000000;
        --positionTextColorPressed: #ffffff;
      }

      .black-and-white {
        --pushBgColor: #d2d2d2;
        --pushTextColor: white;
        --pushBgColorPressed: black;
        --pushTextColorPressed: white;

        --pullBgColor: white;
        --pullTextColor: #d2d2d2;
        --pullBgColorPressed: #e8e8e8;
        --pullTextColorPressed: black;

        --positionBgColor: #f1f1f1;
        --positionTextColor: #424242;
        --positionBgColorPressed: #c0c0c0;
        --positionTextColorPressed: black;
      }`;
    }
  }
}
</script>
