
export const REFERENCES_NOTES = [
    {
        code: "A440",
        fr: "La 440Hz"
    },
]

export const CHORDING = [
    {
        code: "semi-swing",
        fr: "Demi swing",
        cents: 3.30,
    },
    {
        code: "swing",
        fr: "Swing",
        cents: 3.90,
    },
    {
        code: "american-lower-tremolo",
        fr: "Américain atténué",
        cents: 5.90,
    },
    {
        code: "american",
        fr: "Américain",
        cents: 7.10,
    },
    {
        code: "celeste",
        fr: "Celeste",
        cents: 18.50,
    },
    {
        code: "musette",
        fr: "Musette",
        cents: 26.50,
    },
]
// https://fr.wikipedia.org/wiki/Fr%C3%A9quences_des_touches_du_piano
// https://fr.wikipedia.org/wiki/La_440
// I'm using then Anglo saxon notation which is A4 ~440Hz as key
// this came from piano position, each octave starts from C
// data from https://fr.wikipedia.org/wiki/Fr%C3%A9quences_des_touches_du_piano#cite_note-GTM-1
const SCALES_DATA = [
    {
        'major': 'C',
        'major_fr': 'Do',
        'fr': 'Do',
        'minor': 'Am',
        'minor_fr': 'Lam',
        'scale': [
            'C',
            'D',
            'E',
            'F',
            'G',
            'A',
            'B',
        ],
        '#': 0,
        'b': null
    },
    {
        'major': 'C#',
        'major_fr': 'Do#',
        'fr': 'Do#',
        'minor': 'Bbm',
        'minor_fr': 'Sibm',
        'scale': [
            'C#', 
            'Eb',
            'F',
            'F#', 
            'G#', 
            'Bb',
            'C',
        ],
        '#': null,
        'b': 5
    },
    {
        'major': 'D',
        'major_fr': 'Ré',
        'fr': 'Ré',
        'minor': 'Bm',
        'minor_fr': 'Sim',
        'scale': [
            'D',
            'E',
            'F#', 
            'G',
            'A',
            'B',
            'C#', 
        ],
        '#': 2,
        'b': null
    },
    {
        'major': 'Eb',
        'major_fr': 'Mib',
        'fr': 'Mib',
        'minor': 'Cm',
        'minor_fr': 'Dom',
        'scale': [
            'Eb',
            'F',
            'G',
            'G#', 
            'Bb',
            'C',
            'D',
        ],
        '#': null,
        'b': 3
    },
    {
        'major': 'E',
        'major_fr': 'Mi',
        'fr': 'Mi',
        'minor': 'C#m',
        'minor_fr': 'Do#m',
        'scale': [
            'E',
            'F#', 
            'G#', 
            'A',
            'B',
            'C#', 
            'Eb',
        ],
        '#': 4,
        'b': null
    },
    {
        'major': 'F',
        'major_fr': 'Fa',
        'fr': 'Fa',
        'minor': 'Dm',
        'minor_fr': 'Rém',
        'scale': [
            'F',
            'G',
            'A',
            'Bb',
            'C',
            'D',
            'E',
        ],
        '#': null,
        'b': 1
    },
    {
        'major': 'F#',
        'major_fr': 'Fa#',
        'fr': 'Fa#',
        'minor': 'Ebm',
        'minor_fr': 'Mibm',
        'scale': [
            'F#', 
            'G#', 
            'Bb',
            'B',
            'C#', 
            'Eb',
            'F',
        ],
        '#': 6,
        'b': 6
    },
    {
        'major': 'G',
        'major_fr': 'Sol',
        'fr': 'Sol',
        'minor': 'Em',
        'minor_fr': 'Mim',
        'scale': [
            'G',
            'A',
            'B',
            'C',
            'D',
            'E',
            'F#', 
        ],
        '#': 1,
        'b': null
    },
    {
        'major': 'G#',
        'major_fr': 'Sol#',
        'fr': 'Sol#',
        'minor': 'Fm',
        'minor_fr': 'Fam',
        'scale': [
            'G#', 
            'Bb',
            'C',
            'C#', 
            'Eb',
            'F',
            'G',
        ],
        '#': null,
        'b': 4
    },
    {
        'major': 'A',
        'major_fr': 'La',
        'fr': 'La',
        'minor': 'F#m',
        'minor_fr': 'Fa#m',
        'scale': [
            'A',
            'B',
            'C#', 
            'D',
            'E',
            'F#', 
            'G#', 
        ],
        '#': 3,
        'b': null
    },
    {
        'major': 'Bb',
        'major_fr': 'Sib',
        'fr': 'Sib',
        'minor': 'Gm',
        'minor_fr': 'Solm',
        'scale': [
            'Bb',
            'C',
            'D',
            'Eb',
            'F',
            'G',
            'A',
        ],
        '#': null,
        'b': 2
    },
    {
        'major': 'B',
        'major_fr': 'Si',
        'fr': 'Si',
        'minor': 'G#m',
        'minor_fr': 'Sol#m',
        'scale': [
            'B',
            'C#', 
            'Eb',
            'E',
            'F#', 
            'G#', 
            'Bb',
        ],
        '#': 5,
        'b': null
    },
]

export const VOICES = [
    {
        "code": "piccolo",
        "fr": "Piccolo",
        "en": "Piccolo"
    },
    {
        "code": "upper-tremolo",
        "fr": "Flûte haute",
        "en": "Upper Tremolo"
    },
    {
        "code": "clarinet",
        "fr": "Flûte",
        "en": "Clarinet"
    },
    {
        "code": "lower-tremolo",
        "fr": "Flûte grave",
        "en": "Lower tremolo"
    },
    {
        "code": "bassoon",
        "fr": "Basson",
        "en": "Bassoon"
    }
]
function major_to_minor(scale){
    let minor_scale = Object.assign({}, scale);
    minor_scale.scale = [...scale.scale.slice(-2), ...scale.scale.slice(0, -2)];
    minor_scale.fr = minor_scale.minor_fr;
    return minor_scale;
}
export const SCALES = new Map(SCALES_DATA.flatMap(scale => [[scale.major, scale], [scale.minor, major_to_minor(scale)]]));
function get_chord(degrees){
    const get_notes = function (scale){
        return degrees.map(degree => scale[degree - 1]);
    }
    return get_notes
}
export const DEGREES = new Map([
    ["I", {fr: "La tonique", note: get_chord([1]), chord2: get_chord([1, 5]), chord: get_chord([1, 3, 5]), chord4: get_chord([1, 3, 5, 7])}],
    ["II", {fr: "La sus-tonique", note: get_chord([2]), chord2: get_chord([2, 6]), chord: get_chord([2, 4, 6]), chord4: get_chord([2, 4, 6, 1]) }],
    ["III", {fr: "La médiante", note: get_chord([3]), chord2: get_chord([3, 7]), chord: get_chord([3, 5, 7]), chord4: get_chord([3, 5, 7, 2]) }],
    ["IV", {fr: "La sous-dominante", note: get_chord([4]), chord2: get_chord([4, 1]), chord: get_chord([4, 6, 1]), chord4: get_chord([4, 6, 1, 3]) }],
    ["V", {fr: "La dominante", note: get_chord([5]), chord2: get_chord([5, 2]), chord: get_chord([5, 7, 2]), chord4: get_chord([5, 7, 2, 4]) }],
    ["VI", {fr: "La sus-dominante", note: get_chord([6]), chord2: get_chord([6, 3]), chord: get_chord([6, 1, 3]), chord4: get_chord([6, 1, 3, 5]) }],
    ["VII", {fr: "La sous-tonique/La sensible", note: get_chord([7]), chord2: get_chord([7, 4]), chord: get_chord([7, 2, 4]), chord4: get_chord([7, 2, 4, 6]) }],
])

export const NOTES_EN_TO_FR = {
    "B": "Si",
    "Bb": "Sib",
    "A": "La",
    "G#": "Sol#",
    "G": "Sol",
    "F#": "Fa#",
    "F": "Fa",
    "E": "Mi",
    "Eb": "Mib",
    "D": "Ré",
    "C#": "Do#",
    "C": "Do",
}

export const NOTES = {
    "C8": {
        "abc": ["c'''"],
        "midi": ["C7"],
        "latin": "Do7",
        "fr": "Do",
        "anglo": ["C8"],
        "note": "C",
        "hz": 4186.01,
    },
    "B7": {
        "abc": ["b''"],
        "midi": ["B6"],
        "latin": "Si6",
        "fr": "Si",
        "anglo": ["B7"],
        "note": "B",
        "hz": 3951.07,
    },
    "A#7": {
        "abc": ["^a''", "_b''"],
        "midi": ["A#6", "Bb6"],
        "latin": "La6#",
        "fr": "Sib",
        "anglo": ["A#7", "Bb7"],
        "note": "Bb",
        "hz": 3729.31,
    },
    "A7": {
        "abc": ["a''"],
        "midi": ["A6"],
        "latin": "La6",
        "fr": "La",
        "anglo": ["A7"],
        "note": "A",
        "hz": 3520,
    },
    "G#7": {
        "abc": ["^g''", "_a''"],
        "midi": ["G#6", "Ab6"],
        "latin": "Sol6#",
        "fr": "Sol#",
        "anglo": ["G#7", "Ab7"],
        "note": "G#",
        "hz": 3322.44,
    },
    "G7": {
        "abc": ["g''"],
        "midi": ["G6"],
        "latin": "Sol6",
        "fr": "Sol",
        "anglo": ["G7"],
        "note": "G",
        "hz": 3135.96,
    },
    "F#7": {
        "abc": ["^f''", "_g''"],
        "midi": ["F#6", "Gb6"],
        "latin": "Fa6#",
        "fr": "Fa#",
        "anglo": ["F#7", "Gb7"],
        "note": "F#",
        "hz": 2959.96,
    },
    "F7": {
        "abc": ["f''"],
        "midi": ["F6"],
        "latin": "Fa6",
        "fr": "Fa",
        "anglo": ["F7"],
        "note": "F",
        "hz": 2793.83,
    },
    "E7": {
        "abc": ["e''"],
        "midi": ["E6"],
        "latin": "Mi6",
        "fr": "Mi",
        "anglo": ["E7"],
        "note": "E",
        "hz": 2637.02,
    },
    "D#7": {
        "abc": ["^d''", "_e''"],
        "midi": ["D#6", "Eb6"],
        "latin": "Ré6#",
        "fr": "Mib",
        "anglo": ["D#7", "Eb7"],
        "note": "Eb",
        "hz": 2489.02,
    },
    "D7": {
        "abc": ["d''"],
        "midi": ["D6"],
        "latin": "Ré6",
        "fr": "Ré",
        "anglo": ["D7"],
        "note": "D",
        "hz": 2349.32,
    },
    "C#7": {
        "abc": ["^c''", "_d''"],
        "midi": ["C#6", "Db6"],
        "latin": "Do6#",
        "fr": "Do#",
        "anglo": ["C#7", "Db7"],
        "note": "C#",
        "hz": 2217.46,
    },
    "C7": {
        "abc": ["c''"],
        "midi": ["C6"],
        "latin": "Do6",
        "fr": "Do",
        "anglo": ["C7"],
        "note": "C",
        "hz": 2093,
    },
    "B6": {
        "abc": ["b'"],
        "midi": ["B5"],
        "latin": "Si5",
        "fr": "Si",
        "anglo": ["B6"],
        "note": "B",
        "hz": 1975.53,
    },
    "A#6": {
        "abc": ["^a'", "_b'"],
        "midi": ["A#5", "Bb5"],
        "latin": "La5#",
        "fr": "Sib",
        "anglo": ["A#6", "Bb6"],
        "note": "Bb",
        "hz": 1864.66,
    },
    "A6": {
        "abc": ["a'"],
        "midi": ["A5"],
        "latin": "La5",
        "fr": "La",
        "anglo": ["A6"],
        "note": "A",
        "hz": 1760,
    },
    "G#6": {
        "abc": ["^g'", "_a'"],
        "midi": ["G#5", "Ab5"],
        "latin": "Sol5#",
        "fr": "Sol#",
        "anglo": ["G#6", "Ab6"],
        "note": "G#",
        "hz": 1661.22,
    },
    "G6": {
        "abc": ["g'"],
        "midi": ["G5"],
        "latin": "Sol5",
        "fr": "Sol",
        "anglo": ["G6"],
        "note": "G",
        "hz": 1567.98,
    },
    "F#6": {
        "abc": ["^f'", "_g'"],
        "midi": ["F#5", "Gb5"],
        "latin": "Fa5#",
        "fr": "Fa#",
        "anglo": ["F#6", "Gb6"],
        "note": "F#",
        "hz": 1479.98,
    },
    "F6": {
        "abc": ["f'"],
        "midi": ["F5"],
        "latin": "Fa5",
        "fr": "Fa",
        "anglo": ["F6"],
        "note": "F",
        "hz": 1396.91,
    },
    "E6": {
        "abc": ["e'"],
        "midi": ["E5"],
        "latin": "Mi5",
        "fr": "Mi",
        "anglo": ["E6"],
        "note": "E",
        "hz": 1318.51,
    },
    "D#6": {
        "abc": ["^d'", "_e'"],
        "midi": ["D#5", "Eb5"],
        "latin": "Ré5#",
        "fr": "Mib",
        "anglo": ["D#6", "Eb6"],
        "note": "Eb",
        "hz": 1244.51,
    },
    "D6": {
        "abc": ["d'"],
        "midi": ["D5"],
        "latin": "Ré5",
        "fr": "Ré",
        "anglo": ["D6"],
        "note": "D",
        "hz": 1174.66,
    },
    "C#6": {
        "abc": ["^c'", "_d'"],
        "midi": ["C#5", "Db5"],
        "latin": "Do5#",
        "fr": "Do#",
        "anglo": ["C#6", "Db6"],
        "note": "C#",
        "hz": 1108.73,
    },
    "C6": {
        "abc": ["c'"],
        "midi": ["C5"],
        "latin": "Do5",
        "fr": "Do",
        "anglo": ["C6"],
        "note": "C",
        "hz": 1046.5,
    },
    "B5": {
        "abc": ["b"],
        "midi": ["B4"],
        "latin": "Si4",
        "fr": "Si",
        "anglo": ["B5"],
        "note": "B",
        "hz": 987.767,
    },
    "A#5": {
        "abc": ["^a", "_b"],
        "midi": ["A#4", "Bb4"],
        "latin": "La4#",
        "fr": "Sib",
        "anglo": ["A#5", "Bb5"],
        "note": "Bb",
        "hz": 932.328,
    },
    "A5": {
        "abc": ["a"],
        "midi": ["A4"],
        "latin": "La4",
        "fr": "La",
        "anglo": ["A5"],
        "note": "A",
        "hz": 880,
    },
    "G#5": {
        "abc": ["^g", "_a"],
        "midi": ["G#4", "Ab4"],
        "latin": "Sol4#",
        "fr": "Sol#",
        "anglo": ["G#5", "Ab5"],
        "note": "G#",
        "hz": 830.609,
    },
    "G5": {
        "abc": ["g"],
        "midi": ["G4"],
        "latin": "Sol4",
        "fr": "Sol",
        "anglo": ["G5"],
        "note": "G",
        "hz": 783.991,
    },
    "F#5": {
        "abc": ["^f", "_g"],
        "midi": ["F#4", "Gb4"],
        "latin": "Fa4#",
        "fr": "Fa#",
        "anglo": ["F#5", "Gb5"],
        "note": "F#",
        "hz": 739.989,
    },
    "F5": {
        "abc": ["f"],
        "midi": ["F4"],
        "latin": "Fa4",
        "fr": "Fa",
        "anglo": ["F5"],
        "note": "F",
        "hz": 698.456,
    },
    "E5": {
        "abc": ["e"],
        "midi": ["E4"],
        "latin": "Mi4",
        "fr": "Mi",
        "anglo": ["E5"],
        "note": "E",
        "hz": 659.255,
    },
    "D#5": {
        "abc": ["^d", "_e"],
        "midi": ["D#4", "Eb4"],
        "latin": "Ré4#",
        "fr": "Mib",
        "anglo": ["D#5", "Eb5"],
        "note": "Eb",
        "hz": 622.254,
    },
    "D5": {
        "abc": ["d"],
        "midi": ["D4"],
        "latin": "Ré4",
        "fr": "Ré",
        "anglo": ["D5"],
        "note": "D",
        "hz": 587.33,
    },
    "C#5": {
        "abc": ["^c", "_d"],
        "midi": ["C#4", "Db4"],
        "latin": "Do4#",
        "fr": "Do#",
        "anglo": ["C#5", "Db5"],
        "note": "C#",
        "hz": 554.365,
    },
    "C5": {
        "abc": ["c"],
        "midi": ["C4"],
        "latin": "Do4",
        "fr": "Do",
        "anglo": ["C5"],
        "note": "C",
        "hz": 523.251,
    },
    "B4": {
        "abc": ["B"],
        "midi": ["B3"],
        "latin": "Si3",
        "fr": "Si",
        "anglo": ["B4"],
        "note": "B",
        "hz": 493.883,
    },
    "A#4": {
        "abc": ["^A", "_B"],
        "midi": ["A#3", "Bb3"],
        "latin": "La3#",
        "fr": "Sib",
        "anglo": ["A#4", "Bb4"],
        "note": "Bb",
        "hz": 466.164,
    },
    "A4": { // 440Hz
        "abc": ["A"],
        "midi": ["A3"],
        "latin": "La3",
        "fr": "La",
        "anglo": ["A4"],
        "note": "A",
        "hz": 440,
    },
    "G#4": {
        "abc": ["^G", "_A"],
        "midi": ["G#3", "Ab3"],
        "latin": "Sol3#",
        "fr": "Sol#",
        "anglo": ["G#4", "Ab4"],
        "note": "G#",
        "hz": 415.305,
    },
    "G4": {
        "abc": ["G"],
        "midi": ["G3"],
        "latin": "Sol3",
        "fr": "Sol",
        "anglo": ["G4"],
        "note": "G",
        "hz": 391.995,
    },
    "F#4": {
        "abc": ["^F", "_G"],
        "midi": ["F#3", "Gb3"],
        "latin": "Fa3#",
        "fr": "Fa#",
        "anglo": ["F#4", "Gb4"],
        "note": "F#",
        "hz": 369.994,
    },
    "F4": {
        "abc": ["F"],
        "midi": ["F3"],
        "latin": "Fa3",
        "fr": "Fa",
        "anglo": ["F4"],
        "note": "F",
        "hz": 349.228,
    },
    "E4": {
        "abc": ["E"],
        "midi": ["E3"],
        "latin": "Mi3",
        "fr": "Mi",
        "anglo": ["E4"],
        "note": "E",
        "hz": 329.628,
    },
    "D#4": {
        "abc": ["^D", "_E"],
        "midi": ["D#3", "Eb3"],
        "latin": "Ré3#",
        "fr": "Mib",
        "anglo": ["D#4", "Eb4"],
        "note": "Eb",
        "hz": 311.127,
    },
    "D4": {
        "abc": ["D"],
        "midi": ["D3"],
        "latin": "Ré3",
        "fr": "Ré",
        "anglo": ["D4"],
        "note": "D",
        "hz": 293.665,
    },
    "C#4": {
        "abc": ["^C", "_D"],
        "midi": ["C#3", "Db3"],
        "latin": "Do3#",
        "fr": "Do#",
        "anglo": ["C#4", "Db4"],
        "note": "C#",
        "hz": 277.183,
    },
    "C4": {
        "abc": ["C"],
        "midi": ["C3"],
        "latin": "Do3",
        "fr": "Do",
        "anglo": ["C4"],
        "note": "C",
        "hz": 261.626,
    },
    "B3": {
        "abc": ["B,"],
        "midi": ["B2"],
        "latin": "Si2",
        "fr": "Si",
        "anglo": ["B3"],
        "note": "B",
        "hz": 246.942,
    },
    "A#3": {
        "abc": ["^A,", "_B,"],
        "midi": ["A#2", "Bb2"],
        "latin": "La2#",
        "fr": "Sib",
        "anglo": ["A#3", "Bb3"],
        "note": "Bb",
        "hz": 233.082,
    },
    "A3": {
        "abc": ["A,",],
        "midi": ["A2"],
        "latin": "La2",
        "fr": "La",
        "anglo": ["A3"],
        "note": "A",
        "hz": 220,
    },
    "G#3": {
        "abc": ["^G,", "_A,"],
        "midi": ["G#2", "Ab2"],
        "latin": "Sol2#",
        "fr": "Sol#",
        "anglo": ["G#3", "Ab3"],
        "note": "G#",
        "hz": 207.652,
    },
    "G3": {
        "abc": ["G,"],
        "midi": ["G2"],
        "latin": "Sol2",
        "fr": "Sol",
        "anglo": ["G3"],
        "note": "G",
        "hz": 195.998,
    },
    "F#3": {
        "abc": ["^F,", "_G,"],
        "midi": ["F#2", "Gb2"],
        "latin": "Fa2#",
        "fr": "Fa#",
        "anglo": ["F#3", "Gb3"],
        "note": "F#",
        "hz": 184.997,
    },
    "F3": {
        "abc": ["F,"],
        "midi": ["F2"],
        "latin": "Fa2",
        "fr": "Fa",
        "anglo": ["F3"],
        "note": "F",
        "hz": 174.614,
    },
    "E3": {
        "abc": ["E,"],
        "midi": ["E2"],
        "latin": "Mi2",
        "fr": "Mi",
        "anglo": ["E3"],
        "note": "E",
        "hz": 164.814,
    },
    "D#3": {
        "abc": ["^D,", "_E,"],
        "midi": ["D#2", "Eb2"],
        "latin": "Ré2#",
        "fr": "Mib",
        "anglo": ["D#3", "Eb3"],
        "note": "Eb",
        "hz": 155.563,
    },
    "D3": {
        "abc": ["D,"],
        "midi": ["D2"],
        "latin": "Ré2",
        "fr": "Ré",
        "anglo": ["D3"],
        "note": "D",
        "hz": 146.832,
    },
    "C#3": {
        "abc": ["^C,", "_D,"],
        "midi": ["C#2", "Db2"],
        "latin": "Do2#",
        "fr": "Do#",
        "anglo": ["C#3", "Db3"],
        "note": "C#",
        "hz": 138.591,
    },
    "C3": {
        "abc": ["C,"],
        "midi": ["C2"],
        "latin": "Do2",
        "fr": "Do",
        "anglo": ["C3"],
        "note": "C",
        "hz": 130.813,
    },
    "B2": {
        "abc": ["B,,"],
        "midi": ["B1"],
        "latin": "Si1",
        "fr": "Si",
        "anglo": ["B2"],
        "note": "B",
        "hz": 123.471,
    },
    "A#2": {
        "abc": ["^A,,", "_B,,"],
        "midi": ["A#1", "Bb1"],
        "latin": "La1#",
        "fr": "Sib",
        "anglo": ["A#2", "Bb2"],
        "note": "Bb",
        "hz": 116.541,
    },
    "A2": {
        "abc": ["A,,"],
        "midi": ["A1"],
        "latin": "La1",
        "fr": "La",
        "anglo": ["A2"],
        "note": "A",
        "hz": 110,
    },
    "G#2": {
        "abc": ["^G,,", "_A,,"],
        "midi": ["G#1", "Ab1"],
        "latin": "Sol1#",
        "fr": "Sol#",
        "anglo": ["G#2", "Ab2"],
        "note": "G#",
        "hz": 103.826,
    },
    "G2": {
        "abc": ["G,,"],
        "midi": ["G1"],
        "latin": "Sol1",
        "fr": "Sol",
        "anglo": ["G2"],
        "note": "G",
        "hz": 97.9989,
    },
    "F#2": {
        "abc": ["^F,,", "_G,,"],
        "midi": ["F#1", "Gb1"],
        "latin": "Fa1#",
        "fr": "Fa#",
        "anglo": ["F#2", "Gb2"],
        "note": "F#",
        "hz": 92.4986,
    },
    "F2": {
        "abc": ["F,,"],
        "midi": ["F1"],
        "latin": "Fa1",
        "fr": "Fa",
        "anglo": ["F2"],
        "note": "F",
        "hz": 87.3071,
    },
    "E2": {
        "abc": ["E,,"],
        "midi": ["E1"],
        "latin": "Mi1",
        "fr": "Mi",
        "anglo": ["E2"],
        "note": "E",
        "hz": 82.4069,
    },
    "D#2": {
        "abc": ["^D,,", "_E,,"],
        "midi": ["D#1", "Eb1"],
        "latin": "Ré1#",
        "fr": "Mib",
        "anglo": ["D#2", "Eb2"],
        "note": "Eb",
        "hz": 77.7817,
    },
    "D2": {
        "abc": ["D,,"],
        "midi": ["D1"],
        "latin": "Ré1",
        "fr": "Ré",
        "anglo": ["D2"],
        "note": "D",
        "hz": 73.4162,
    },
    "C#2": {
        "abc": ["^C,,", "_D,,"],
        "midi": ["C#1", "Db1"],
        "latin": "Do1#",
        "fr": "Do#",
        "anglo": ["C#2", "Db2"],
        "note": "C#",
        "hz": 69.2957,
    },
    "C2": {
        "abc": ["C,,"],
        "midi": ["C1"],
        "latin": "Do1",
        "fr": "Do",
        "anglo": ["C2"],
        "note": "C",
        "hz": 65.4064,
    },
    "B1": {
        "abc": ["B,,,"],
        "midi": ["B0"],
        "latin": "Si0",
        "fr": "Si",
        "anglo": ["B1"],
        "note": "B",
        "hz": 61.7354,
    },
    "A#1": {
        "abc": ["^A,,,", "_B,,,"],
        "midi": ["A#0", "Bb0"],
        "latin": "La0#",
        "fr": "Sib",
        "anglo": ["A#1", "Bb1"],
        "note": "Bb",
        "hz": 58.2705,
    },
    "A1": {
        "abc": ["A,,,"],
        "midi": ["A0"],
        "latin": "La0",
        "fr": "La",
        "anglo": ["A1"],
        "note": "A",
        "hz": 55,
    },
    "G#1": {
        "abc": ["^G,,,", "_A,,,"],
        "midi": ["G#0", "Ab0"],
        "latin": "Sol0#",
        "fr": "Sol#",
        "anglo": ["G#1", "Ab1"],
        "note": "G#",
        "hz": 51.9130,
    },
    "G1": {
        "abc": ["G,,,"],
        "midi": ["G0"],
        "latin": "Sol0",
        "fr": "Sol",
        "anglo": ["G1"],
        "note": "G",
        "hz": 48.9995,
    },
    "F#1": {
        "abc": ["^F,,,", "_G,,,"],
        "midi": ["F#0", "Gb0"],
        "latin": "Fa0#",
        "fr": "Fa#",
        "anglo": ["F#1", "Gb1"],
        "note": "F#",
        "hz": 46.2493,
    },
    "F1": {
        "abc": ["F,,,"],
        "midi": ["F0"],
        "latin": "Fa0",
        "fr": "Fa",
        "anglo": ["F1"],
        "note": "F",
        "hz": 43.6536,
    },
    "E1": {
        "abc": ["E,,,"],
        "midi": ["E0"],
        "latin": "Mi0",
        "fr": "Mi",
        "anglo": ["E1"],
        "note": "E",
        "hz": 41.2035,
    },
    "D#1": {
        "abc": ["^D,,,", "_E,,,"],
        "midi": ["D#0", "Eb0"],
        "latin": "Ré0#",
        "fr": "Mib",
        "anglo": ["D#1", "Eb1"],
        "note": "Eb",
        "hz": 38.8909,
    },
    "D1": {
        "abc": ["D,,,"],
        "midi": ["D0"],
        "latin": "Ré0",
        "fr": "Ré",
        "anglo": ["D1"],
        "note": "D",
        "hz": 36.7081,
    },
    "C#1": {
        "abc": ["^C,,,", "_D,,,"],
        "midi": ["C#0", "Db0"],
        "latin": "Do0#",
        "fr": "Do#",
        "anglo": ["C#1", "Db1"],
        "note": "C#",
        "hz": 34.6479,
    },
    "C1": {
        "abc": ["C,,,"],
        "midi": ["C0"],
        "latin": "Do0",
        "fr": "Do",
        "anglo": ["C1"],
        "note": "C",
        "hz": 32.7032,
    },
    "B0": {
        "abc": ["B,,,,"],
        "midi": ["B-1"],
        "latin": "Si-1",
        "fr": "S-1",
        "anglo": ["B0"],
        "note": "B",
        "hz": 30.8677,
    },
    "A#0": {
        "abc": ["^A,,,,", "_B,,,,"],
        "midi": ["A#-1", "Bb-1"],
        "latin": "La-1#",
        "fr": "L-1#",
        "anglo": ["A#0", "Bb0"],
        "note": "Bb",
        "hz": 29.1353,
    },
    "A0": {
        "abc": ["A,,,,"],
        "midi": ["A-1"],
        "latin": "La-1",
        "fr": "L-1",
        "anglo": ["A0"],
        "note": "A",
        "hz": 27.5,
    }
}
// keyboards https://diato.org/clavier_diato.htm
// buttons position from top(chin) to bottom (knees)
// rows position from outside to the middle of the instruments
// notes use the Anglo saxon notation
// Mapping is possible using above NOTES TABLE


export const LEFT_ROWS_GC_8_0 = [
    {
        "code": "L_ROW-1",
        "fr": "",
        "symbol": "",
        "position": 1,
        "buttons": [
            {
                "position": 1,
                "push": "g",
                "pull": "d"
            },
            {
                "position": 2,
                "push": "G",
                "pull": "D"
            },
            {
                "position": 3,
                "push": "c",
                "pull": "g"
            },
            {
                "position": 4,
                "push": "C",
                "pull": "G"
            },
        ],
    },
    {
        "code": "L_ROW-2",
        "symbol": "",
        "fr": "",
        "position": 2,
        "buttons": [
            {
                "position": 1,
                "push": "e",
                "pull": "am"
            },
            {
                "position": 2,
                "push": "E",
                "pull": "A"
            },
            {
                "position": 3,
                "push": "f",
                "pull": "f"
            },
            {
                "position": 4,
                "push": "F",
                "pull": "F"
            },
        ],
    }
]
export const LEFT_ROWS_GC_12_0 = [
    {
        "code": "L_ROW-1",
        "symbol": "",
        "fr": "",
        "position": 1,
        "buttons": [
            {
                "position": 1,
                "push": "g#",
                "pull": "b"
            },
            {
                "position": 2,
                "push": "G#",
                "pull": "B"
            },
            {
                "position": 3,
                "push": "g",
                "pull": "d"
            },
            {
                "position": 4,
                "push": "G",
                "pull": "D"
            },
            {
                "position": 5,
                "push": "c",
                "pull": "g"
            },
            {
                "position": 6,
                "push": "C",
                "pull": "G"
            },
        ],
    },
    {
        "code": "L_ROW-2",
        "fr": "",
        "symbol": "",
        "position": 2,
        "buttons": [
            {
                "position": 1,
                "push": "eb",
                "pull": "bb"
            },
            {
                "position": 2,
                "push": "Eb",
                "pull": "Bb"
            },
            {
                "position": 3,
                "push": "e",
                "pull": "a"
            },
            {
                "position": 4,
                "push": "E",
                "pull": "A"
            },
            {
                "position": 5,
                "push": "f",
                "pull": "f"
            },
            {
                "position": 6,
                "push": "F",
                "pull": "F"
            },
        ],
    },
]
export const LEFT_ROWS_GC_12_1 = [
    {
        "code": "L_ROW-1",
        "symbol": "",
        "fr": "",
        "position": 1,
        "buttons": [
            {
                "position": 1,
                "push": "g",
                "pull": "d"
            },
            {
                "position": 2,
                "push": "G",
                "pull": "D"
            },
            {
                "position": 3,
                "push": "c",
                "pull": "g"
            },
            {
                "position": 4,
                "push": "C",
                "pull": "G"
            },
            {
                "position": 5,
                "push": "g#",
                "pull": "eb"
            },
            {
                "position": 6,
                "push": "G#",
                "pull": "Eb"
            },
        ],
    },
    {
        "code": "L_ROW-2",
        "fr": "",
        "symbol": "",
        "position": 2,
        "buttons": [
            {
                "position": 1,
                "push": "e",
                "pull": "a"
            },
            {
                "position": 2,
                "push": "E",
                "pull": "A"
            },
            {
                "position": 3,
                "push": "f",
                "pull": "f"
            },
            {
                "position": 4,
                "push": "F",
                "pull": "F"
            },
            {
                "position": 5,
                "push": "b",
                "pull": "bb"
            },
            {
                "position": 6,
                "push": "B",
                "pull": "Bb"
            },
        ],
    },
]
export const LEFT_ROWS_GC_HEIM_12_2  = [
    {
        "code": "L_ROW-1",
        "symbol": "",
        "fr": "",
        "position": 1,
        "buttons": [
            {
                "position": 1,
                "push": "g#",
                "pull": "bm"
            },
            {
                "position": 2,
                "push": "G#",
                "pull": "B"
            },
            {
                "position": 3,
                "push": "g",
                "pull": "d"
            },
            {
                "position": 4,
                "push": "G",
                "pull": "D"
            },
            {
                "position": 5,
                "push": "c",
                "pull": "g"
            },
            {
                "position": 6,
                "push": "C",
                "pull": "G"
            },
        ],
    },
    {
        "code": "L_ROW-2",
        "fr": "",
        "symbol": "",
        "position": 2,
        "buttons": [
            {
                "position": 1,
                "push": "eb",
                "pull": "bb"
            },
            {
                "position": 2,
                "push": "Eb",
                "pull": "Bb"
            },
            {
                "position": 3,
                "push": "e",
                "pull": "am"
            },
            {
                "position": 4,
                "push": "E",
                "pull": "A"
            },
            {
                "position": 5,
                "push": "f",
                "pull": "f"
            },
            {
                "position": 6,
                "push": "F",
                "pull": "F"
            },
        ],
    },
]
export const LEFT_ROWS_GC_18_0 = [...LEFT_ROWS_GC_12_0, 
    {
        "code": "L_ROW-3",
        "fr": "",
        "symbol": "",
        "position": 3,
        "buttons": [
            {
                "position": 1,
                "push": "b",
                "pull": "c#"
            },
            {
                "position": 2,
                "push": "B",
                "pull": "C#"
            },
            {
                "position": 3,
                "push": "a",
                "pull": "c"
            },
            {
                "position": 4,
                "push": "A",
                "pull": "C"
            },
            {
                "position": 5,
                "push": "d",
                "pull": "f#"
            },
            {
                "position": 6,
                "push": "D",
                "pull": "F#"
            },
        ],
    }
]
export const LEFT_ROWS_GC_18_1 = [...LEFT_ROWS_GC_12_1, 
    {
        "code": "L_ROW-3",
        "fr": "",
        "symbol": "",
        "position": 3,
        "buttons": [
            {
                "position": 1,
                "push": "a",
                "pull": "c"
            },
            {
                "position": 2,
                "push": "A",
                "pull": "C"
            },
            {
                "position": 3,
                "push": "d",
                "pull": "f#"
            },
            {
                "position": 4,
                "push": "D",
                "pull": "F#"
            },
            {
                "position": 5,
                "push": "c#",
                "pull": "b"
            },
            {
                "position": 6,
                "push": "C#",
                "pull": "B"
            },
        ],
    }
]
export const LEFT_ROWS_GC_HEIM_18_2 = [...LEFT_ROWS_GC_HEIM_12_2,
    {
        "code": "L_ROW-3",
        "fr": "",
        "symbol": "",
        "position": 3,
        "buttons": [
            {
                "position": 1,
                "push": "b",
                "pull": "c#m"
            },
            {
                "position": 2,
                "push": "B",
                "pull": "C#"
            },
            {
                "position": 3,
                "push": "a",
                "pull": "f#m"
            },
            {
                "position": 4,
                "push": "A",
                "pull": "F#"
            },
            {
                "position": 5,
                "push": "d",
                "pull": "c"
            },
            {
                "position": 6,
                "push": "D",
                "pull": "C"
            },
        ],
    }]







export const LEFT_ROWS_AD_8_0 = [
    {
        "code": "L_ROW-1",
        "symbol": "",
        "fr": "",
        "position": 1,
        "buttons": [
            {
                "position": 1,
                "push": "a",
                "pull": "e"
            },
            {
                "position": 2,
                "push": "A",
                "pull": "E"
            },
            {
                "position": 3,
                "push": "d",
                "pull": "a"
            },
            {
                "position": 4,
                "push": "D",
                "pull": "A"
            },
        ],
    },
    {
        "code": "L_ROW-2",
        "fr": "",
        "symbol": "",
        "position": 2,
        "buttons": [
            {
                "position": 1,
                "push": "f#",
                "pull": "b"
            },
            {
                "position": 2,
                "push": "F#",
                "pull": "B"
            },
            {
                "position": 3,
                "push": "g",
                "pull": "g"
            },
            {
                "position": 4,
                "push": "G",
                "pull": "G"
            },
        ],
    },
]
export const LEFT_ROWS_AD_12_0 = [
    {
        "code": "L_ROW-1",
        "symbol": "",
        "fr": "",
        "position": 1,
        "buttons": [
            {
                "position": 1,
                "push": "bb",
                "pull": "c#"
            },
            {
                "position": 2,
                "push": "Bb",
                "pull": "C#"
            },
            {
                "position": 3,
                "push": "a",
                "pull": "e"
            },
            {
                "position": 4,
                "push": "A",
                "pull": "E"
            },
            {
                "position": 5,
                "push": "d",
                "pull": "a"
            },
            {
                "position": 6,
                "push": "D",
                "pull": "A"
            },
        ],
    },
    {
        "code": "L_ROW-2",
        "fr": "",
        "symbol": "",
        "position": 2,
        "buttons": [
            {
                "position": 1,
                "push": "f",
                "pull": "c"
            },
            {
                "position": 2,
                "push": "F",
                "pull": "C"
            },
            {
                "position": 3,
                "push": "f#",
                "pull": "b"
            },
            {
                "position": 4,
                "push": "F#",
                "pull": "B"
            },
            {
                "position": 5,
                "push": "g",
                "pull": "g"
            },
            {
                "position": 6,
                "push": "G",
                "pull": "G"
            },
        ],
    },
]

export const LEFT_ROWS_BbEb_18_0 = [
    {
        "code": "L_ROW-1",
        "symbol": "",
        "fr": "",
        "position": 1,
        "buttons": [
            {
                "position": 1,
                "push": "b",
                "pull": "d"
            },
            {
                "position": 2,
                "push": "B",
                "pull": "d"
            },
            {
                "position": 3,
                "push": "bb",
                "pull": "f"
            },
            {
                "position": 4,
                "push": "Bb",
                "pull": "F"
            },
            {
                "position": 5,
                "push": "eb",
                "pull": "bb"
            },
            {
                "position": 6,
                "push": "Eb",
                "pull": "Bb"
            },
        ],
    },
    {
        "code": "L_ROW-2",
        "fr": "",
        "symbol": "",
        "position": 2,
        "buttons": [
            {
                "position": 1,
                "push": "f#",
                "pull": "c#"
            },
            {
                "position": 2,
                "push": "F#",
                "pull": "C#"
            },
            {
                "position": 3,
                "push": "g",
                "pull": "c"
            },
            {
                "position": 4,
                "push": "G",
                "pull": "C"
            },
            {
                "position": 5,
                "push": "g#",
                "pull": "g#"
            },
            {
                "position": 6,
                "push": "G#",
                "pull": "G#"
            },
        ],
    },
    {
        "code": "L_ROW-3",
        "fr": "",
        "symbol": "",
        "position": 3,
        "buttons": [
            {
                "position": 1,
                "push": "d",
                "pull": "e"
            },
            {
                "position": 2,
                "push": "D",
                "pull": "E"
            },
            {
                "position": 3,
                "push": "c",
                "pull": "eb"
            },
            {
                "position": 4,
                "push": "C",
                "pull": "Eb"
            },
            {
                "position": 5,
                "push": "f",
                "pull": "a"
            },
            {
                "position": 6,
                "push": "F",
                "pull": "A"
            },
        ],
    }
]

export const KEYBOARDS = {
    "G-C-alts-treble": {
        "code": "G-C-alts-treble",
        "fr": "Sol/Do/Alts - Clavier 3 rangs: 33 boutons (notes suppl. dans l'aigu) - 18 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "B2",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    },
                    {
                        "position": 12,
                        "push": "G6",
                        "pull": "C6"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "F3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                    {
                        "position": 11,
                        "push": "G6",
                        "pull": "D6"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#3",
                        "pull": "A#3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "G4"
                    },
                    {
                        "position": 4,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 9,
                        "push": "D#6",
                        "pull": "A#5"
                    },
                    {
                        "position": 10,
                        "push": "G#6",
                        "pull": "C#6"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_18_0
    },
    "G-C-alts": {
        "code": "G-C-alts",
        "fr": "Sol/Do/Alts - Clavier 3 rangs: 30 boutons - 18 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "B2",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "F3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#3",
                        "pull": "A#3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "G4"
                    },
                    {
                        "position": 4,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 9,
                        "push": "D#6",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_18_0
    },
    "G-C-alts-10-9-8-2": {
        // Game level not properly test
        "code": "G-C-alts-10-9-8-2",
        "fr": "Sol/Do/Alts - Clavier 3 rangs: 27 boutons - 12 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 3,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 4,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 6,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 7,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 9,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 10,
                        "push": "D6",
                        "pull": "A5"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 2,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 3,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 4,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 5,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 6,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 7,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 8,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 9,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "A3",
                        "pull": "D#4"
                    },
                    {
                        "position": 2,
                        "push": "C#4",
                        "pull": "G4"
                    },
                    {
                        "position": 3,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 4,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 5,
                        "push": "C#5",
                        "pull": "D#5"
                    },
                    {
                        "position": 6,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 7,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 8,
                        "push": "C#6",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_12_1
    },
    "G-C-alts-heim-10-9-8-2": {
        // Game level not properly test
        "code": "G-C-alts-heim-10-9-8-2",
        "fr": "Sol/Do/Alts - Heim - Clavier 3 rangs: 27 boutons - 12 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 3,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 4,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 6,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 7,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 9,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 10,
                        "push": "D6",
                        "pull": "A5"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 2,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 3,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 4,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 5,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 6,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 7,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 8,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 9,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 2,
                        "push": "D#4",
                        "pull": "G4"
                    },
                    {
                        "position": 3,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 4,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 5,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 6,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 7,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 8,
                        "push": "D#6",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_HEIM_12_2
    },
    "G-C-alts-10-9-8-18": {
        // Game level not properly test
        "code": "G-C-alts-10-9-8-18",
        "fr": "Sol/Do/Alts - Clavier 3 rangs: 27 boutons - 18 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 3,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 4,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 6,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 7,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 9,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 10,
                        "push": "D6",
                        "pull": "A5"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 2,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 3,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 4,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 5,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 6,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 7,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 8,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 9,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "A3",
                        "pull": "D#4"
                    },
                    {
                        "position": 2,
                        "push": "C#4",
                        "pull": "G4"
                    },
                    {
                        "position": 3,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 4,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 5,
                        "push": "C#5",
                        "pull": "D#5"
                    },
                    {
                        "position": 6,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 7,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 8,
                        "push": "C#6",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_18_1
    },
    "G-C-alts-treble-12b": {
        "code": "G-C-alts-treble-12b",
        "fr": "Sol/Do/Alts - Clavier 3 rangs: 33 boutons (notes suppl. dans l'aigu) - 12 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "B2",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    },
                    {
                        "position": 12,
                        "push": "G6",
                        "pull": "C6"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "F3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                    {
                        "position": 11,
                        "push": "G6",
                        "pull": "D6"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#3",
                        "pull": "A#3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "G4"
                    },
                    {
                        "position": 4,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 9,
                        "push": "D#6",
                        "pull": "A#5"
                    },
                    {
                        "position": 10,
                        "push": "G#6",
                        "pull": "C#6"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_12_0
    },
    "sol-do": {
        "code": "sol-do",
        "fr": "Sol/Do - Clavier 2 rangs: 21 boutons - 8 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "D#4",
                        "pull": "C#5"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#4",
                        "pull": "A#4"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_8_0
    },
    "sol-do+2": {
        "code": "sol-do+2",
        "fr": "Sol/Do/Alts - Clavier 2 rangs + 2: 23 boutons - 8 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "B3",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "G3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 4,
                        "push": "D#4",
                        "pull": "C#5"
                    },
                    {
                        "position": 5,
                        "push": "G#4",
                        "pull": "A#4"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_8_0
    },
    "sol-do-alts": {
        "code": "sol-do-alts",
        "fr": "Sol/Do/Alts - Clavier 3 rangs: 30 boutons - 12 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "B2",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    }
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "F3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#3",
                        "pull": "A#3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "G4"
                    },
                    {
                        "position": 4,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 9,
                        "push": "D#6",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_12_0
    },
    "sol-do+6": {
        "code": "sol-do+6",
        "fr": "Sol/Do/Alts - Clavier 2 rangs +6: 27 boutons - 12 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#3",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    }
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "F3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "C#4"
                    },
                    {
                        "position": 4,
                        "push": "A4",
                        "pull": "G4"
                    },
                    {
                        "position": 5,
                        "push": "G#4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "A5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "G#5",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_12_0
    },
    "sol-do+6-18basses": {
        "code": "sol-do+6-18basses",
        "fr": "Sol/Do/Alts - Clavier 2 rangs +6: 27 boutons - 18 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#3",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    }
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "F3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "C#4"
                    },
                    {
                        "position": 4,
                        "push": "A4",
                        "pull": "G4"
                    },
                    {
                        "position": 5,
                        "push": "G#4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "A5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "G#5",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_18_0
    },
    "sol-do-petiot": {
        "code": "sol-do-petiot",
        "fr": "Sol/Do - Petiot: Clavier 2 rangs: 10 boutons - 2 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 2,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 3,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 4,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 5,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "D5",
                        "pull": "C5"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 2,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 3,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 4,
                        "push": "C5",
                        "pull": "B4"
                    },
                ]
            }
        ],
        "left_rows": [
            {
                "code": "L_ROW-1",
                "fr": "",
                "symbol": "",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "g",
                        "pull": "d"
                    },
                    {
                        "position": 2,
                        "push": "G",
                        "pull": "D"
                    },
                ],
            },
        ] 
    },
    "G-C-FHs":{
        "code": "G-C-FHs",
        "fr": "Sol/Do/Alts - Clavier François HEIM Standard - 3 rangs: 33 boutons - 18 basses",
        "right_rows": [
            {
                "code": "R_ROW-G",
                "symbol": "",
                "fr": "Sol",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "B2",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#3"
                    },
                    {
                        "position": 3,
                        "push": "G3",
                        "pull": "A3"
                    },
                    {
                        "position": 4,
                        "push": "B3",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G4",
                        "pull": "F#4"
                    },
                    {
                        "position": 7,
                        "push": "B4",
                        "pull": "A4"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 9,
                        "push": "G5",
                        "pull": "E5"
                    },
                    {
                        "position": 10,
                        "push": "B5",
                        "pull": "F#5"
                    },
                    {
                        "position": 11,
                        "push": "D6",
                        "pull": "A5"
                    },
                    {
                        "position": 12,
                        "push": "G6",
                        "pull": "C6"
                    },
                ]
            },
            {
                "code": "R_ROW-C",
                "symbol": "'",
                "fr": "Do",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "G3"
                    },
                    {
                        "position": 2,
                        "push": "G3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F4"
                    },
                    {
                        "position": 5,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 6,
                        "push": "C5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 9,
                        "push": "C6",
                        "pull": "A5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                    {
                        "position": 11,
                        "push": "G6",
                        "pull": "D6"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G#3",
                        "pull": "A#3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "G4"
                    },
                    {
                        "position": 4,
                        "push": "G#4",
                        "pull": "G#4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "G#5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G#5"
                    },
                    {
                        "position": 9,
                        "push": "D#6",
                        "pull": "A#5"
                    },
                    {
                        "position": 10,
                        "push": "G#6",
                        "pull": "C#6"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_GC_HEIM_18_2
    },
    "A-D-alts": {
        "code": "A-D-alts",
        "fr": "La/Ré/Alts - Clavier 3 rangs: 27 boutons - 12 basses",
        "right_rows": [
            {
                "code": "R_ROW-A",
                "symbol": "",
                "fr": "La",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "D#3",
                        "pull": "F3"
                    },
                    {
                        "position": 2,
                        "push": "E3",
                        "pull": "G#3"
                    },
                    {
                        "position": 3,
                        "push": "A3",
                        "pull": "B3"
                    },
                    {
                        "position": 4,
                        "push": "C#4",
                        "pull": "D4"
                    },
                    {
                        "position": 5,
                        "push": "E4",
                        "pull": "F#4"
                    },
                    {
                        "position": 6,
                        "push": "A4",
                        "pull": "G#4"
                    },
                    {
                        "position": 7,
                        "push": "C#5",
                        "pull": "B4"
                    },
                    {
                        "position": 8,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 9,
                        "push": "A5",
                        "pull": "F#5"
                    },
                    {
                        "position": 10,
                        "push": "C#6",
                        "pull": "G#5"
                    },
                ]
            },
            {
                "code": "R_ROW-D",
                "symbol": "'",
                "fr": "Ré",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "F#3",
                        "pull": "G3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 3,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 4,
                        "push": "F#4",
                        "pull": "G4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "B4"
                    },
                    {
                        "position": 6,
                        "push": "D5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "F#5",
                        "pull": "E5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G5"
                    },
                    {
                        "position": 9,
                        "push": "D6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "A#3",
                        "pull": "C4"
                    },
                    {
                        "position": 2,
                        "push": "B3",
                        "pull": "D#4"
                    },
                    {
                        "position": 3,
                        "push": "F4",
                        "pull": "A4"
                    },
                    {
                        "position": 4,
                        "push": "A#4",
                        "pull": "A#4"
                    },
                    {
                        "position": 5,
                        "push": "B4",
                        "pull": "C5"
                    },
                    {
                        "position": 6,
                        "push": "F5",
                        "pull": "D#5"
                    },
                    {
                        "position": 7,
                        "push": "A#5",
                        "pull": "A5"
                    },
                    {
                        "position": 8,
                        "push": "B5",
                        "pull": "A#5"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_AD_12_0
    },
    "A-D": {
        "code": "A-D",
        "fr": "La/Ré - Clavier 2 rangs: 21 boutons - 8 basses",
        "right_rows": [
            {
                "code": "R_ROW-A",
                "symbol": "",
                "fr": "La",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "F3",
                        "pull": "D#3"
                    },
                    {
                        "position": 2,
                        "push": "E3",
                        "pull": "G#3"
                    },
                    {
                        "position": 3,
                        "push": "A3",
                        "pull": "B3"
                    },
                    {
                        "position": 4,
                        "push": "C#4",
                        "pull": "D4"
                    },
                    {
                        "position": 5,
                        "push": "E4",
                        "pull": "F#4"
                    },
                    {
                        "position": 6,
                        "push": "A4",
                        "pull": "G#4"
                    },
                    {
                        "position": 7,
                        "push": "C#5",
                        "pull": "B4"
                    },
                    {
                        "position": 8,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 9,
                        "push": "A5",
                        "pull": "F#5"
                    },
                    {
                        "position": 10,
                        "push": "C#6",
                        "pull": "G#5"
                    },
                    {
                        "position": 11,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-D",
                "symbol": "'",
                "fr": "Ré",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "A#3",
                        "pull": "C4"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 3,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 4,
                        "push": "F#4",
                        "pull": "G4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "B4"
                    },
                    {
                        "position": 6,
                        "push": "D5",
                        "pull": "C#5"
                    },
                    {
                        "position": 7,
                        "push": "F#5",
                        "pull": "E5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G5"
                    },
                    {
                        "position": 9,
                        "push": "D6",
                        "pull": "B5"
                    },
                    {
                        "position": 10,
                        "push": "F#6",
                        "pull": "C#6"
                    },
                ]
            },
        ],
        "left_rows": LEFT_ROWS_AD_8_0
    },
    "A-D-alt-r3": {
        "code": "A-D-alt-r3",
        "fr": "La/Ré - Clavier 2 rangs + 2: 21 boutons - 8 basses",
        "right_rows": [
            {
                "code": "R_ROW-A",
                "symbol": "",
                "fr": "La",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "E3",
                        "pull": "G#3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "B3"
                    },
                    {
                        "position": 3,
                        "push": "C#4",
                        "pull": "D4"
                    },
                    {
                        "position": 4,
                        "push": "E4",
                        "pull": "F#4"
                    },
                    {
                        "position": 5,
                        "push": "A4",
                        "pull": "G#4"
                    },
                    {
                        "position": 6,
                        "push": "C#5",
                        "pull": "B4"
                    },
                    {
                        "position": 7,
                        "push": "E5",
                        "pull": "D5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "F#5"
                    },
                    {
                        "position": 9,
                        "push": "C#6",
                        "pull": "G#5"
                    },
                    {
                        "position": 10,
                        "push": "E6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-D",
                "symbol": "'",
                "fr": "Ré",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "A3",
                        "pull": "C#4"
                    },
                    {
                        "position": 2,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 3,
                        "push": "F#4",
                        "pull": "G4"
                    },
                    {
                        "position": 4,
                        "push": "A4",
                        "pull": "B4"
                    },
                    {
                        "position": 5,
                        "push": "D5",
                        "pull": "C#5"
                    },
                    {
                        "position": 6,
                        "push": "F#5",
                        "pull": "E5"
                    },
                    {
                        "position": 7,
                        "push": "A5",
                        "pull": "G5"
                    },
                    {
                        "position": 8,
                        "push": "D6",
                        "pull": "B5"
                    },
                    {
                        "position": 9,
                        "push": "F#6",
                        "pull": "C#6"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 3,
                        "push": "F3",
                        "pull": "D#3"
                    },
                    {
                        "position": 4,
                        "push": "A#3",
                        "pull": "C4"
                    },
                ],
            },
        ],
        "left_rows": LEFT_ROWS_AD_8_0
    },
    "Bb-Eb-alts-treble": {
        "code": "Bb-Eb-alts-treble",
        "fr": "Sib/Mib/Alts - Clavier 3 rangs: 33 boutons (notes suppl. dans l'aigu) - 18 basses",
        "right_rows": [
            {
                "code": "R_ROW-Bb",
                "symbol": "",
                "fr": "Sib",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "D3",
                        "pull": "G3"
                    },
                    {
                        "position": 2,
                        "push": "F3",
                        "pull": "A3"
                    },
                    {
                        "position": 3,
                        "push": "A#3",
                        "pull": "C4"
                    },
                    {
                        "position": 4,
                        "push": "D4",
                        "pull": "D#4"
                    },
                    {
                        "position": 5,
                        "push": "F4",
                        "pull": "G4"
                    },
                    {
                        "position": 6,
                        "push": "A#4",
                        "pull": "A4"
                    },
                    {
                        "position": 7,
                        "push": "D5",
                        "pull": "C5"
                    },
                    {
                        "position": 8,
                        "push": "F5",
                        "pull": "D#5"
                    },
                    {
                        "position": 9,
                        "push": "A#5",
                        "pull": "G5"
                    },
                    {
                        "position": 10,
                        "push": "D6",
                        "pull": "A5"
                    },
                    {
                        "position": 11,
                        "push": "F6",
                        "pull": "C6"
                    },
                    {
                        "position": 12,
                        "push": "A#6",
                        "pull": "D#6"
                    },
                ]
            },
            {
                "code": "R_ROW-Eb",
                "symbol": "'",
                "fr": "Mib",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "G3",
                        "pull": "G#3"
                    },
                    {
                        "position": 2,
                        "push": "A#3",
                        "pull": "D4"
                    },
                    {
                        "position": 3,
                        "push": "D#4",
                        "pull": "F4"
                    },
                    {
                        "position": 4,
                        "push": "G4",
                        "pull": "G#4"
                    },
                    {
                        "position": 5,
                        "push": "A#4",
                        "pull": "C5"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "D5"
                    },
                    {
                        "position": 7,
                        "push": "G5",
                        "pull": "F5"
                    },
                    {
                        "position": 8,
                        "push": "A#5",
                        "pull": "G#5"
                    },
                    {
                        "position": 9,
                        "push": "D#6",
                        "pull": "C6"
                    },
                    {
                        "position": 10,
                        "push": "G6",
                        "pull": "D6"
                    },
                    {
                        "position": 11,
                        "push": "A#6",
                        "pull": "F6"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "Altérations",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "B3",
                        "pull": "C#4"
                    },
                    {
                        "position": 2,
                        "push": "C4",
                        "pull": "E4"
                    },
                    {
                        "position": 3,
                        "push": "F#4",
                        "pull": "A#4"
                    },
                    {
                        "position": 4,
                        "push": "B4",
                        "pull": "B4"
                    },
                    {
                        "position": 5,
                        "push": "C5",
                        "pull": "C#5"
                    },
                    {
                        "position": 6,
                        "push": "D#5",
                        "pull": "E5"
                    },
                    {
                        "position": 7,
                        "push": "B5",
                        "pull": "A#5"
                    },
                    {
                        "position": 8,
                        "push": "C6",
                        "pull": "B5"
                    },
                    {
                        "position": 9,
                        "push": "F#6",
                        "pull": "C#6"
                    },
                    {
                        "position": 10,
                        "push": "B6",
                        "pull": "E6"
                    },
                ]
            }
        ],
        "left_rows": LEFT_ROWS_BbEb_18_0
    },
    "irlandais-30-12": {
        // Irlandais
        // Game level not properly test
        "code": "irlandais-30-12",
        "fr": "Irlandais - Clavier 3 rangs: 27 boutons - 12 basses",
        "right_rows": [
            {
                "code": "R_ROW-1",
                "symbol": "",
                "fr": "1",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "F2",
                        "pull": "G3"
                    },
                    {
                        "position": 2,
                        "push": "G#3",
                        "pull": "C3"
                    },
                    {
                        "position": 3,
                        "push": "C#3",
                        "pull": "D#3"
                    },
                    {
                        "position": 4,
                        "push": "F3",
                        "pull": "F#4"
                    },
                    {
                        "position": 5,
                        "push": "G#4",
                        "pull": "A#4"
                    },
                    {
                        "position": 6,
                        "push": "C#4",
                        "pull": "C4"
                    },
                    {
                        "position": 7,
                        "push": "F4",
                        "pull": "D#4" 
                    },
                    {
                        "position": 8,
                        "push": "G#5",
                        "pull": "F#5"
                    },
                    {
                        "position": 9,
                        "push": "C#5",
                        "pull": "A#5"
                    },
                    {
                        "position": 10,
                        "push": "F5",
                        "pull": "C5"
                    },
                ]
            },
            {
                "code": "R_ROW-2",
                "symbol": "'",
                "fr": "2",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "F#3",
                        "pull": "B3"
                    },
                    {
                        "position": 2,
                        "push": "A3",
                        "pull": "C#3"
                    },
                    {
                        "position": 3,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 4,
                        "push": "F#4",
                        "pull": "G4"
                    },
                    {
                        "position": 5,
                        "push": "A4", // 440
                        "pull": "B4"
                    },
                    {
                        "position": 6,
                        "push": "D5",
                        "pull": "C#4"
                    },
                    {
                        "position": 7,
                        "push": "F#5",
                        "pull": "E5"
                    },
                    {
                        "position": 8,
                        "push": "A5",
                        "pull": "G5"
                    },
                    {
                        "position": 9,
                        "push": "D6",
                        "pull": "B5"
                    },
                ]
            },
            {
                "code": "R_ROW-Alts",
                "symbol": '"',
                "fr": "3",
                "position": 3,
                "buttons": [
                    {
                        "position": 1,
                        "push": "A#3",
                        "pull": "E3"
                    },
                    {
                        "position": 2,
                        "push": "D3",
                        "pull": "F#4"
                    },
                    {
                        "position": 3,
                        "push": "G4",
                        "pull": "A4"
                    },
                    {
                        "position": 4,
                        "push": "B4",
                        "pull": "C4"
                    },
                    {
                        "position": 5,
                        "push": "D4",
                        "pull": "E4"
                    },
                    {
                        "position": 6,
                        "push": "G5",
                        "pull": "F#5"
                    },
                    {
                        "position": 7,
                        "push": "B5",
                        "pull": "A5"
                    },
                    {
                        "position": 8,
                        "push": "D5",
                        "pull": "C5"
                    },
                ]
            }
        ],
        "left_rows": [
            {
                "code": "L_ROW-1",
                "symbol": "",
                "fr": "",
                "position": 1,
                "buttons": [
                    {
                        "position": 1,
                        "push": "eb",
                        "pull": "f#"
                    },
                    {
                        "position": 2,
                        "push": "Eb",
                        "pull": "F#"
                    },
                    {
                        "position": 3,
                        "push": "d",
                        "pull": "a"
                    },
                    {
                        "position": 4,
                        "push": "D",
                        "pull": "A"
                    },
                    {
                        "position": 5,
                        "push": "g",
                        "pull": "d"
                    },
                    {
                        "position": 6,
                        "push": "G",
                        "pull": "D"
                    },
                ],
            },
            {
                "code": "L_ROW-2",
                "symbol": "",
                "fr": "",
                "position": 2,
                "buttons": [
                    {
                        "position": 1,
                        "push": "bb",
                        "pull": "f"
                    },
                    {
                        "position": 2,
                        "push": "Bb",
                        "pull": "F"
                    },
                    {
                        "position": 3,
                        "push": "b",
                        "pull": "e"
                    },
                    {
                        "position": 4,
                        "push": "B",
                        "pull": "E"
                    },
                    {
                        "position": 5,
                        "push": "c",
                        "pull": "c"
                    },
                    {
                        "position": 6,
                        "push": "C",
                        "pull": "C"
                    },
                ],
            }
        ]
    },
}



export const MODELS = [
    {
        "code": "petiot",
        "fr": "Petiot - 10 touches (6+4) - 2 basses",
        "keyboards": [
            "sol-do-petiot",
        ]
    },
    {
        "code": "jig",
        "fr": "Jig - 21 touches (10+9+2) - 8 basses", 
        "keyboards": [ "A-D-alt-r3"]
    },
    {
        "code": "jig+",
        "fr": "Jig+ - 23 touches (11+10+2) - 8 basses", 
        "keyboards": ["sol-do+2"]
    },
    {
        "code": "cercle",
        "fr": "Cercle - 27 touches (10+9+8) - 12 basses", 
        "keyboards": [
            "G-C-alts-10-9-8-2",
            "G-C-alts-heim-10-9-8-2",
            "A-D-alts",
            "irlandais-30-12"
        ]
    },
    {
        "code": "jibidi",
        "fr": "Jibidi - 27 touches (10+9+8) - 18 basses", 
        "keyboards": [
            "G-C-alts-10-9-8-18"
        ]
    },
    {
        "code": "an-dro",
        "fr": "An Dro - 21 touches (11+10) - 8 basses", 
        "keyboards": [
            "sol-do",
            "A-D"
        ]
    },
    {
        "code": "an-dro+",
        "fr": "An Dro+ - 23 touches (11+10+2) - 8 basses", 
        "keyboards": ["sol-do+2"]
    },
    {
        "code": "hanter-dro",
        "fr": "Hanter Dro - 26 touches (11+10+5) - 12 basses", 
        "keyboards": [
            "sol-do+6",
            "sol-do+6-18basses"
        ]
    },
    {
        "code": "plinn",
        "fr": "Plinn - 30 touches (11+10+9) - 12 basses", 
        "keyboards": [
            "sol-do-alts",
        ]
    },
    {
        "code": "plinn+",
        "fr": "Plinn - 33 touches (12+11+10) - 12 basses", 
        "keyboards": [
            "G-C-alts-treble-12b",
        ]
    },
    {
        "code": "gavotte",
        "fr": "Gavotte - 30 touches (11+10+9) - 18 basses", 
        "keyboards": [
            "G-C-alts",
        ]
    },
    {
        "code": "gavotte+",
        "fr": "Gavotte - 33 touches (12+11+10) - 18 basses", 
        "keyboards": [
            "G-C-alts-treble",
            "Bb-Eb-alts-treble",
            "G-C-FHs"
        ]
    },
]
