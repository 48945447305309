<template>
  <!-- https://en.wikipedia.org/wiki/Accordion_reed_ranks_and_switches#Register_stop_classifications -->
  <svg :x="x" :y="y">
    <text v-if="referenceNote" x="110" y="25" style="font: 20px sans-serif;">Fréquence: {{referenceNote.fr}}</text>
    <text 
      v-if="upperTremoloChording && voices.indexOf('upper-tremolo')>-1"
      x="110"
      y="50"
      style="font: 20px sans-serif;"
    >Flûte 1: {{upperTremoloChording.fr}}({{upperTremoloChording.cents.toFixed(2)}} cents)</text>
    <text 
      v-if="lowerTremoloChording && voices.indexOf('lower-tremolo')>-1"
      x="110"
      y="75"
      style="font: 20px sans-serif;"
    >Flûte 2: {{lowerTremoloChording.fr}}({{lowerTremoloChording.cents.toFixed(2)}} cents)</text>
      
    <circle
      fill="var(--positionBgColor)"
      fill-opacity="1"
      stroke="var(--positionTextColor)"
      stroke-width="3"
      stroke-opacity="1"
      cx="50"
      cy="50"
      r="49" />
    <line
      stroke="var(--positionTextColor)"
      stroke-width="3"
      x1="6.7" y1="33.33" x2="93.3" y2="33.33"/>
    <line
      stroke="var(--positionTextColor)"
      stroke-width="3"
      x1="6.7" y1="66.66" x2="93.3" y2="66.66"/>

    <circle
      id="piccolo"
      v-if="voices.indexOf('piccolo')>-1"
      fill="var(--positionTextColor)"
      fill-opacity="1"
      stroke="none"
      cx="50"
      cy="16.5"
      r="10" />
    <circle
      id="lower-tremolo"
      v-if="voices.indexOf('lower-tremolo')>-1"
      fill="var(--positionTextColor)"
      fill-opacity="1"
      stroke="none"
      cx="16.5"
      cy="50"
      r="10" />
    <circle
      id="clarinet"
      v-if="voices.indexOf('clarinet')>-1"
      fill="var(--positionTextColor)"
      fill-opacity="1"
      stroke="none"
      cx="50"
      cy="50"
      r="10" />
    <circle
      id="upper-tremolo"
      v-if="voices.indexOf('upper-tremolo')>-1"
      fill="var(--positionTextColor)"
      fill-opacity="1"
      stroke="none"
      cx="83.16"
      cy="50"
      r="10" />
    <circle
      id="bassoon"
      v-if="voices.indexOf('bassoon')>-1"
      fill="var(--positionTextColor)"
      fill-opacity="1"
      stroke="none"
      cx="50"
      cy="83.16"
      r="10" />
  </svg>
</template>

<script>

export default {
  name: 'DiatonicVoices',
  props: {
    referenceNote: {
      type: Object,
      default: null,
    },
    upperTremoloChording: {
      type: Object,
      default: null,
    },
    lowerTremoloChording: {
      type: Object,
      default: null,
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    voices: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
  }
}
</script>

<style>
  /** 
    mind to update export style as well
    reuse same class as button style
  */
  .mediterranean {
    --positionBgColor: #edece8;
    --positionTextColor: #034488;
  }

  .petrol {
    --positionBgColor: #C8EBBF;
    --positionTextColor: #072400;
  }

  .black-and-white {
    --positionBgColor: #f1f1f1;
    --positionTextColor: #424242;
  }

  .bulma {
    --positionBgColor: hsl(0, 0%, 96%);
    --positionTextColor: hsl(0, 0%, 48%);
  }
</style>
