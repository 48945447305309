<template>
  <svg :x="x" :y="y" :width="width" :height="height">
    <rect
        :key="index"
        :x="bellow.x"
        y="0"
        rx="75"
        :width="bellow.width"
        height="100%"
        :fill="fillColor"
        :fill-opacity="fillOpacity"
        :stroke="strokeColor"
        :stroke-width="strokeWidth"
        :stroke-opacity="strokeOpacity"
         v-for="bellow, index in bellows" 
        />
  </svg>
</template>

<script>
export default {
  name: 'Bellows',
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 1500
    },
    fillOpacity: {
      type: Number,
      default: 1
    },
    fillColor: {
      type: String,
      default: "blue"
    },
    strokeColor: {
      type: String,
      default: "none"
    },
    strokeWidth: {
      type: Number,
      default: 1
    },
    strokeOpacity: {
      type: Number,
      default: 0
    },
    folder: {
      type: Number,
      default: 8
    }
  },
  computed: {
      bellows(){
          const width = (this.width - 20) / this.folder;
          const bellows = [];
          for (let i = 0; i <= this.folder -1 ; i++) {
            bellows.push({x: i * width - i / 2, width: width});
          }
          return bellows;
      }
  }
}
</script>